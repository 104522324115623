<template>
  <section class="section__funds">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__funds-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            @ShowHelp="showHelp()"
            :items="getFundsList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="formAdd">
                <v-card-text>
                  <v-text-field
                    :rules="nameRules"
                    label="عنوان صندوق"
                    hide-details
                    outlined
                    dense
                    v-model="addData.title"
                  ></v-text-field>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-text-field
                    :rules="nameRules"
                    label="عنوان صندوق"
                    hide-details
                    outlined
                    dense
                    v-model="editData.data.title"
                  ></v-text-field>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__funds-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft.vue";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "Funds",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      dialog: false,
      search: null,
      addData: {
        url: "cashdesk/create/",
        formName: "formAdd",
        Title: null,
      },
      editData: {
        url: "cashdesk/update/",
        formName: "formEdit",
        data: { Title: null },
      },
      removeData: { url: "cashdesk/delete" },
      headers: [
        {
          text: "عنوان صندوق",
          align: "right",
          value: "title",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  mounted() {
    this.getFunds();
  },
  computed: {
    ...mapGetters(["getFundsList", "getBaseUrl"]),
  },
  methods: {
    showHelp(){
      this.$helperDialog.showHelper({
        url:'/help/settingApp/accountingSystem/funds/funds.txt',
        video:'/help/settingApp/accountingSystem/funds/funds.mp4'})
    },
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "cashdesk/list/",
        page: 1,
        Phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "cashdesk/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getFunds() {
      this.$store.dispatch("sendList", {
        url: "cashdesk/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getBaseUrl}/cashdesk/get/`, {
          params: {
            Id: item.cashDeskId,
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response, "==============");
          this.editData.data = response.data.record;
          this.editData.data.test = this.$refs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__funds {
  background-color: #eeeeee !important;
}
</style>
