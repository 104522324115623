const helperDialogPlugin = {
    install: (Vue, store) => {
        if (!store) {
            throw new Error('Please provide vuex store.');
        }

        Vue.prototype.$helperDialog = {
            showHelper: function ({url = '', video = ''}) {
                fetch(url).then(res => {
                    return res.text();
                }).then(text => {
                    store.commit(
                        'showHelper',
                        {text, video},
                        {root: true}
                    );
                }).catch(err => {
                    console.log(err);
                })

            }
        };
    },
};
export default helperDialogPlugin;