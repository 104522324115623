<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :items="getEceList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="form">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        hide-details
                        label="عنوان"
                        outlined
                        dense
                        v-model="addData.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :rules="nameRules"
                        :items="getEceemails.records"
                        label="ایمیل ECE"
                        hide-details
                        outlined
                        item-text="title"
                        item-value="eCEEmailId"
                        dense
                        v-model="addData.eCEEmailFk"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :rules="nameRules"
                        :items="getAndikatorsList.records"
                        label="اندیکاتور"
                        hide-details
                        outlined
                        item-text="andikatorTitle"
                        item-value="andikatorId"
                        dense
                        v-model="addData.andikatorFk"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :rules="nameRules"
                        :items="getorgpostList.records"
                        label="پست سازمانی"
                        hide-details
                        outlined
                        item-text="orgPostTitle"
                        item-value="orgPostId"
                        dense
                        v-model="addData.orgPostFk"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        label="وارده"
                        v-model="addData.in"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        label="صادره"
                        v-model="addData.out"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        hide-details
                        label="عنوان"
                        outlined
                        dense
                        v-model="editData.data.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :rules="nameRules"
                        :items="getEceemails.records"
                        label="ایمیل ECE"
                        hide-details
                        outlined
                        item-text="title"
                        item-value="eCEEmailId"
                        dense
                        v-model="editData.data.eCEEmailFk"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :rules="nameRules"
                        :items="getAndikatorsList.records"
                        label="اندیکاتور"
                        hide-details
                        outlined
                        item-text="andikatorTitle"
                        item-value="andikatorId"
                        dense
                        v-model="editData.data.andikatorFk"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :rules="nameRules"
                        :items="getorgpostList.records"
                        label="پست سازمانی"
                        hide-details
                        outlined
                        item-text="orgPostTitle"
                        item-value="orgPostId"
                        dense
                        v-model="editData.data.orgPostFk"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        label="وارده"
                        v-model="editData.data.in"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        label="صادره"
                        v-model="editData.data.out"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      addData: {
        url: "ece/create/",
        AndikatorFk: null,
        eCEEmailFk: null,
        in: true,
        orgPostFk: null,
        out: true,
        title: null,
        test: this.$refs,
      },
      editData: {
        url: "ece/update/",
        data: {
          AndikatorFk: null,
          eCEEmailFk: null,
          in: true,
          orgPostFk: null,
          out: true,
          title: null,
        },
      },
      removeData: { url: "/ece/delete/" },
      headers: [
        {
          text: "عنوان بانک",
          align: "right",
          value: "title",
        },
        {
          text: "ایمیل ECE",
          align: "right",
          value: "eCEEmailFk",
        },
        {
          text: "اندیکاتور",
          align: "right",
          value: "andikatorFk",
        },
        {
          text: "پست سازمانی",
          align: "right",
          value: "orgPostFk",
        },
        {
          text: "وارده",
          align: "right",
          value: "in",
        },
        {
          text: "صادره",
          align: "right",
          value: "out",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getEceList",
      "getEceemails",
      "getorgpostList",
      "getBaseUrl",
      "getAndikatorsList",
    ]),
  },
  created() {
    this.getBanks();
    this.getEmailsECE();
    this.getAndikator();
    this.getorgpost();
  },
  methods: {
    getEmailsECE() {
      this.$store.dispatch("sendList", {
        url: "eceemails/list/",
        page: 1,
        Phrase: "",
      });
    },
    getorgpost() {
      this.$store.dispatch("sendList", {
        url: "orgpost/list/",
        page: 1,
        Phrase: "",
      });
    },
    getAndikator() {
      this.$store.dispatch("sendList", {
        url: "andikator/list/",
        page: 1,
        Phrase: "",
      });
    },
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "ece/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "ece/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getBanks() {
      this.$store.dispatch("sendList", {
        url: "ece/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      console.log(item, "----------------");
      axios
        .get(`${this.getBaseUrl}/ece/get/`, {
          params: {
            Id: item.eCEId,
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response);
          this.editData.data = response.data.record;
          this.editData.data.test = this.$refs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
