<template>
  <section class="section__headlines">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__headlines-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            @ShowHelp="showHelp()"
            :items="getAccountsList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            :add="true"
            :edit="true"
            :remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="formAdd">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان"
                        hide-details
                        outlined
                        dense
                        v-model="addData.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        label="سرفصل اصلی"
                        hide-details
                        outlined
                        :items="getAccountsListFilterd"
                        item-text="title"
                        item-value="accountId"
                        dense
                        v-model="addData.parentFk"
                        clearable
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان"
                        hide-details
                        outlined
                        dense
                        v-model="editData.data.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        label="سرفصل اصلی"
                        hide-details
                        outlined
                        :items="getAccountsListFilterd"
                        item-text="title"
                        item-value="accountId"
                        dense
                        v-model="editData.data.parentFk"
                        clearable
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__headlines-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft.vue";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "Headlines",
  mixins: [myMixin],
  data() {
    return {
      searchTest: "",
      dialog: false,
      search: null,
      addData: {
        url: "account/create/",
        formName: "formAdd",
        title: null,
        parentFk: null,
      },
      editData: {
        url: "account/update/",
        formName: "formEdit",
        data: {
          accountId: null,
          isDemo: false,
          parentFk: null,
        },
      },
      removeData: { url: "account/delete/" },
      headers: [
        {
          text: "عنوان ",
          align: "right",
          value: "title",
        },
        {
          text: "سرفصل اصلی ",
          align: "center",
          value: "parentFk",
        },
        // {
        //   text: "مرتبط با دامین ",
        //   align: "center",
        //   value: "",
        // },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
      DomainList: [],
    };
  },
  components: {
    MenuLeft,
    AccountingSystem,
  },
  mounted() {
    this.getAccounts();
  },
  computed: {
    ...mapGetters(["getAccountsList", "getBaseUrl"]),
    getAccountsListFilterd() {
      return this.getAccountsList.records?.filter((x) => x.parentFk == null);
    },
  },
  methods: {
    showHelp(){
      this.$helperDialog.showHelper({
        url:'/help/settingApp/accountingSystem/headlines/headlines.txt',
        video:'/help/settingApp/accountingSystem/headlines/headlines.mp4'})
    },
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "account/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "account/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getAccounts() {
      this.$store.dispatch("sendList", {
        url: "account/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getBaseUrl}/account/get/`, {
          params: {
            Id: item.accountId,
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response, "==============");
          this.editData.data = response.data.record;
          this.editData.data.test = this.$refs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__headlines {
  background-color: #eeeeee !important;
}
</style>
