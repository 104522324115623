<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <v-card class="rounded-lg">
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="closeDelete"
                    >لغو</v-btn
                  >
                  <v-btn color="error" variant="text" @click="deleteItemConfirm"
                    >بله</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="addForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="addForm = false">
                  ایجاد
                </v-card-title-close>
                <v-form ref="form">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-text-field
                          v-model="addData.templateName"
                          label="عنوان گزارش"
                          hide-details
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-col cols="12">
                          <v-file-input
                            v-model="addData.file"
                            color="deep-purple-accent-4"
                            counter
                            label="انتخاب فایل"
                            placeholder="انتخاب فایل"
                            prepend-icon="mdi-paperclip"
                            variant="outlined"
                            :show-size="1000"
                            v-show="true"
                            ref="fileInput"
                          ></v-file-input>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="addItem()" color="success">افزودن</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="editForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="editForm = false">
                  ویرایش
                </v-card-title-close>
                <v-form ref="formEdit">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editData.data.templateName"
                          label="عنوان گزارش"
                          hide-details
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-col cols="12">
                          <v-file-input
                            v-model="editData.data.file"
                            color="deep-purple-accent-4"
                            counter
                            label="انتخاب فایل"
                            placeholder="انتخاب فایل"
                            prepend-icon="mdi-paperclip"
                            variant="outlined"
                            :show-size="1000"
                            v-show="true"
                            ref="fileInput"
                          ></v-file-input>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="editItem()" color="success">ویرایش</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-card-title>{{ title }}</v-card-title>
                </v-col>
                <v-col cols="6" class="text-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-3 mt-3"
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="sendShowHelp"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>راهنما</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12">
                  <v-btn class="mr-2 success" @click="addForm = true">
                    <v-icon small>mdi-plus</v-icon>
                    افزودن
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
            <v-card-title>
              <v-text-field
                v-model="search"
                outlined
                dense
                append-icon="mdi-magnify"
                label="جستجو"
                @input="searchBoxChanged"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <div>
                <v-data-table
                  :headers="headers"
                  :items="getTemplateList.records"
                  hide-default-footer
                  :items-per-page="20"
                  :server-items-length="getTemplateList.record_count"
                  :page="1"
                  :fixed-header="true"
                  :loading="getLoading"
                >
                  <template v-slot:[`item.isPublic`]="{ item }">
                    <span>{{ item.isPublic ? "عمومی" : "اختصاصی" }}</span>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          :href="
                            getBaseUrl +
                            '/template/download?templateId=' +
                            item.templateManagerId +
                            '&token=' +
                            token
                          "
                        >
                          <v-icon> mdi-download </v-icon>
                        </a>
                      </template>
                      <span>دانلود</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="!item.isPublic">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          @click="openFormEdit(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>ویرایش</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="!item.isPublic">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          color="error"
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>حذف</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center pt-2"></div>
                <v-row>
                  <v-col
                    cols="12"
                    v-if="Math.ceil(getTemplateList?.record_count / 20) > 1"
                  >
                    <v-pagination
                      v-model="page"
                      :length="
                        getTemplateList?.record_count > 0
                          ? Math.ceil(getTemplateList.record_count / 20)
                          : 0
                      "
                      @input="handlePageChange"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft.vue";
import VCardTitleClose from "../../components/VCardTitleClose";

import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "TemplateManager",
  mixins: [myMixin],
  components: {
    VCardTitleClose,
    MenuLeft,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      search: "",
      page: 1,
      title: "مدیریت قالب نامه",
      dialogDelete: false,
      addForm: false,
      editForm: false,
      addData: {
        url: "template/create/",
        templateName: "",
        file: null,
      },
      editData: {
        url: "template/update/",
        data: {
          templateName: "",
          test: this.$refs,
          file: null,
        },
      },
      removeData: { url: "template/delete/" },
      headers: [
        {
          text: "عنوان ",
          align: "start",
          value: "templateName",
        },
        {
          text: "نوع",
          align: "center",
          value: "isPublic",
        },
        {
          text: "نام فایل",
          align: "center",
          value: "fileName",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  mounted() {
    this.getPage(1);
  },
  computed: {
    ...mapGetters(["getTemplateList", "getBaseUrl", "getLoading"]),
  },
  methods: {
    getPage(page) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "template/list/",
        page: page,
        phrase: this.search,
      });
    },
    handlePageChange(page) {
      this.getPage(page);
    },
    searchBoxChanged() {
      this.getPage(1);
    },

    addItem() {
      this.$store.commit("changeLoading", true);
      axios
        .post(
          `${this.getBaseUrl}/template/create/`,
          {
            Language: 0,
            TemplateName: this.addData.templateName,
            file: this.addData.file,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 2000,
            });
            this.getPage(1);
            this.addForm = false;
          } else {
            this.$store.commit("setMessage", {
              message: data.data.messge,
              color: "red",
            });
          }
        })
        .catch((error) => {
          this.$store.commit("setMessage", {
            message: error.message,
            color: "red",
            timeOut: 2000,
          });
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("changeLoading", false);
        });
    },

    openFormEdit(item) {
      this.editForm = true;
      this.editData.data = JSON.parse(JSON.stringify(item));
    },
    editItem() {
      if (this.$refs.formEdit.validate()) {
        this.$store.commit("changeLoading", true);

        axios
          .put(
            `${this.getBaseUrl}/template/update/`,
            {
              Id: this.editData.data.templateManagerId,
              Language: 0,
              TemplateName: this.editData.data.templateName,
              file: this.editData.data.file,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((data) => {
            if (data.data.status == 1) {
              this.getPage(1);
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
              });
              this.editForm = false;
            } else if (data.data.status == 0) {
              this.$store.commit("setMessage", {
                message: data.data.message,
                color: "red",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.commit("changeLoading", false);
          });
      }
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.removeData = item;
      this.removeData.url = "template/delete/";
    },
    deleteItemConfirm() {
      this.$store.dispatch("sendRemoveItem", {
        data: this.removeData,
      });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    sendShowHelp() {
      this.$helperDialog.showHelper({
        url:'/help/settingApp/basicInformationOfficeAutomation/templateManager/templateManager.txt',
        video:'/help/settingApp/basicInformationOfficeAutomation/templateManager/templateManager.mp4'})

    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
