var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-list',{attrs:{"dense":""}},[_vm._l((this.desserts1),function(item,index){return [_c('v-list-item',{key:item.name},[_c('v-list-item-avatar',{attrs:{"color":"grey lighten-3"}},[(item.createdUserPic)?_c('img',{attrs:{"src":_vm.getFileUrl + 'media/userpic/' + item.createdUserPic}}):_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('span',[(item.messageTypeFk == 4)?_c('v-chip',{staticClass:"message-badge",attrs:{"color":"blue"}},[_c('router-link',{staticStyle:{"color":"white"},attrs:{"to":{
                    name: 'Letter',
                    params: {
                      create: false,
                      pagetype: 'Inbox',
                      messagetype: item.messageTypeFk,
                      id: item.messageId,
                    },
                  }}},[_c('span',[_vm._v(_vm._s(item.messageTypeString))])])],1):(item.messageTypeFk == 5)?_c('v-chip',{staticClass:"message-badge",attrs:{"color":"pink"}},[_c('router-link',{staticStyle:{"color":"white"},attrs:{"to":{
                    name: 'Letter',
                    params: {
                      create: false,
                      pagetype: 'Inbox',
                      messagetype: item.messageTypeFk,
                      id: item.messageId,
                    },
                  }}},[_c('span',[_vm._v(_vm._s(item.messageTypeString))])])],1):_c('v-chip',{staticClass:"message-badge",attrs:{"color":"teal"}},[_c('router-link',{staticStyle:{"color":"white"},attrs:{"to":{
                    name: 'Letter',
                    params: {
                      create: false,
                      pagetype: 'Inbox',
                      messagetype: item.messageTypeFk,
                      id: item.messageId,
                    },
                  }}},[_c('span',[_vm._v(_vm._s(item.messageTypeString))])])],1)],1),_c('span',[_vm._v(" "+_vm._s(item.messageFrom)+" ")])]),_c('span',[_vm._v(_vm._s(item.subject))]),_c('span',{staticClass:"message-body",domProps:{"innerHTML":_vm._s(item.messageBody)}})],1)],1)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }