<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <v-card class="rounded-lg">
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="closeDelete"
                    >لغو</v-btn
                  >
                  <v-btn color="error" variant="text" @click="deleteItemConfirm"
                    >بله</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="addForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="addForm = false">
                  ایجاد
                </v-card-title-close>
                <v-form ref="form">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="عنوان گروه مشتری"
                          hide-details
                          outlined
                          dense
                          v-model="addData.customerGroupTitle"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="addItem()" color="success">افزودن</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="editForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="editForm = false">
                  ویرایش
                </v-card-title-close>
                <v-form ref="formEdit">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="عنوان گروه مشتری"
                          hide-details
                          outlined
                          dense
                          v-model="editData.data.customerGroupTitle"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="editItem()" color="success">ویرایش</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-card-title>{{ title }}</v-card-title>
                </v-col>
                <v-col cols="6" class="text-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          class="ml-3 mt-3"
                          color="warning"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="ShowHelp()"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>راهنما</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12">
                  <v-btn class="mr-2 success" @click="addForm = true">
                    <v-icon small>mdi-plus</v-icon>
                    افزودن
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>

            <v-card-title>
              <v-text-field
                v-model="search"
                outlined
                dense
                append-icon="mdi-magnify"
                label="جستجو"
                @input="searchBoxChanged"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>

            <v-card-text>
              <div>
                <v-data-table
                  :headers="headers"
                  :items="getcustomergroupList.records"
                  hide-default-footer
                  :items-per-page="20"
                  :server-items-length="getcustomergroupList.record_count"
                  :page="1"
                  :fixed-header="true"
                  :loading="getLoading"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          @click="openFormEdit(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>ویرایش</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          color="error"
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>حذف</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center pt-2"></div>
                <v-row>
                  <v-col
                    cols="12"
                    v-if="
                      Math.ceil(getcustomergroupList?.record_count / 20) > 1
                    "
                  >
                    <v-pagination
                      v-model="page"
                      :length="
                        getcustomergroupList?.record_count > 0
                          ? Math.ceil(getcustomergroupList.record_count / 20)
                          : 0
                      "
                      @input="handlePageChange"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import MenuLeft from "../../components/MenuLeft";
import VCardTitleClose from "../../components/VCardTitleClose";
import { mapGetters } from "vuex";
export default {
  name: "Banks",
  components: {
    VCardTitleClose,
    MenuLeft,
  },
  data() {
    return {
      search: "",
      page: 1,
      title: "دسته بندی مشتریان",
      dialogDelete: false,
      addForm: false,
      editForm: false,
      addData: { url: "customergroup/create/", customerGroupTitle: null },
      editData: {
        url: "customergroup/update/",
        data: { customerGroupTitle: null },
      },
      removeData: null,
      headers: [
        {
          text: "عنوان گروه مشتری",
          align: "start",
          value: "customerGroupTitle",
        },

        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getcustomergroupList", "getBaseUrl", "getLoading"]),
  },
  created() {
    this.getCustomergroupCategory();
  },
  methods: {
    ShowHelp(){
      this.$helperDialog.showHelper({
        url:'/help/customer/customerCategory/customerCategory.txt',
        video:'/help/customer/customerCategory/customerCategory.mp4'})
    },
    getPage(page) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "customergroup/list/",
        page: page,
        phrase: this.search,
      });
    },
    handlePageChange(page) {
      this.getPage(page);
    },
    searchBoxChanged() {
      this.getPage(1);
    },

    getCustomergroupCategory() {
      this.$store.dispatch("sendList", {
        url: "customergroup/list/",
        page: 1,
        phrase: "",
      });
    },

    getDataShowHelp() {
      alert("help!!");
    },
    sampleHelp() {},

    addItem() {
      this.$store.dispatch("sendNewitem", { data: this.addData });
      this.addForm = false;
    },

    openFormEdit(item) {
      this.editForm = true;
      this.editData.data = JSON.parse(JSON.stringify(item));
    },
    editItem() {
      if (this.$refs.formEdit.validate()) {
        this.$store.dispatch("sendEditItem", {
          data: this.editData,
          id: this.editData.customergroupId,
        });
        this.editForm = false;
      }
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.removeData = item;
      this.removeData.url = "customergroup/delete/";
    },
    deleteItemConfirm() {
      this.$store.dispatch("sendRemoveItem", {
        data: this.removeData,
      });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
