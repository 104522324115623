import { render, staticRenderFns } from "./DraftMessage.vue?vue&type=template&id=509e7a61&scoped=true"
import script from "./DraftMessage.vue?vue&type=script&lang=js"
export * from "./DraftMessage.vue?vue&type=script&lang=js"
import style0 from "./DraftMessage.vue?vue&type=style&index=0&id=509e7a61&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "509e7a61",
  null
  
)

export default component.exports