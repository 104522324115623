<template>
  <v-app class="">
    <v-dialog max-width="800" v-model="showUserSettingDialog">
      <UserSetting @close="showUserSettingDialog = false"/>
    </v-dialog>
    <v-dialog max-width="800" v-model="showUserApproveDialog">
      <UserApprove @close="showUserApproveDialog = false"/>
    </v-dialog>
    <HelperDialog/>
    <div class="black">
      <v-app-bar v-if="hasToken" color="#EEEEEE" elevate-on-scroll flat app dense>
        <v-row justify="end" class="no-gutters">
          <v-col xs="1" class="d-lg-none"
          >
            <v-icon v-on:click="drawer = !drawer" class="mt-3">
              mdi-menu
            </v-icon>
          </v-col>

          <v-col cols="5" md="3" lg="3" class="text-end">
            <v-menu
                top
                offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                       v-bind="attrs"
                       v-on="on"
                >
                  <v-icon
                      color="warning"
                      dark
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="dashboardHelp()">
                  <v-list-item-title> راهنمای داشبورد</v-list-item-title>
                </v-list-item>
                <v-list-item @click="initHelp()">
                  <v-list-item-title>راهنمای کلی</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu>
              <template v-slot:activator="{ attrs, on }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-badge
                      offset-y="10"
                      color="green"
                      :content="$store.state.notifications.length"
                      :value="$store.state.notifications.length"
                  >
                    <v-icon>mdi-bell-outline</v-icon>
                  </v-badge>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                    v-for="data in this.$store.state.notifications"
                    :key="data.id"
                    link
                    @click="onNotificationClick(data)"
                >
                  <v-list-item-title>{{ data.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-hover v-slot="{ hover }" open-delay="200">
              <v-btn icon v-on:click="removeToken">
                <v-icon :class="{ changeColor: hover }">mdi-exit-to-app</v-icon>
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </v-app-bar>

      <v-navigation-drawer
          width="240"
          v-model="drawer"
          :mini-variant.sync="mini"
          app
          right
          v-if="hasToken"
          id="main-menu"
          style="direction: ltr"
      >
        <v-card class="a fill-height" color="#08092D" style="direction: rtl">
          <v-card max-width="450" class="" tile>
            <v-toolbar color="#08092D" flat dark>
              <v-app-bar-nav-icon
                  center
                  class="d-none d-lg-block"
                  @click.stop="mini = !mini"
              ></v-app-bar-nav-icon>
            </v-toolbar>
          </v-card>
          <!-- پروفایل -->
          <v-card
              dark
              max-width="260"
              class=" mx-auto"
              style="background: rgba(120, 77, 167, 0.2)"
              :style="changebackgroundColor ? 'background: rgb(120, 77, 167 , 0.2)' : `background:${getColor}` "
              flat
          >
            <v-list>
              <v-list-item class="px-2">
                <v-list-item-avatar>
                  <v-img :src="getUserProfile"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="caption">
                    <span style="font-familly: Vazirmatn">{{ this.$store.state.CurrentUser.name || "" }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption">{{ this.$store.state.CurrentUser.username || "" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                      @click="closeOnClick = !closeOnClick"
                      icon
                  >
                    <v-icon>{{ closeOnClick ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-expand-transition>
              <v-card
                  class="mx-auto"
                  v-show="closeOnClick"
                  max-width="260"
                  :style="!changebackgroundColor ? 'background: rgb(120, 77, 167 , 0.1)' : `background:${getColor}`"
                  dark
                  flat
              >
                <v-list dense select-strategy="classic">
                  <v-list-item-group>
                    <v-list-item
                        class="mt-1"
                        @click="showUserSettingDialog = true"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-account-key</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>تنظیمات حساب کاربری</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item @click="showUserApproveDialog = true">
                      <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>تنظیمات پروفایل</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item @click="removeToken">
                      <v-list-item-icon>
                        <v-icon>mdi-exit-to-app</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>خروج</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-expand-transition>
          </v-card>

          <!-- منو های نرم افزار -->
          <v-card
              dark
              max-width="260"
              :style="!changebackgroundColor ? 'background: rgb(120, 77, 167)' : `background:${getColor};` "
              class="mx-auto menu"
          >
            <v-list v-for="(menuItem, index) in getMenu" :key="index" shaped class="py-0">
              <MenuNavigationItem :menu-item="menuItem" :icon="icons[index].icon" :index="index"/>
            </v-list>
          </v-card>
        </v-card>
      </v-navigation-drawer>

      <v-main class="main-scroll">
        <v-snackbar
            :timeout="getMessage.timeOut"
            v-model="snackbar"
            :color="getMessage.color"
            :absolute="false"
            :top="true"
            :left="true"
        >
          {{ getMessage.message }}
        </v-snackbar>
        <!-- <router-view :key="$route.fullPath"></router-view> -->
        <router-view class="grey lighten-5"></router-view>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import Cookies from 'js-cookie';
import {mapState, mapGetters} from "vuex";
import UserSetting from "./components/UserSetting.vue";
import UserApprove from "./components/UserApprove.vue";
import TaskDetail from "./latelier/client/components/tasks/TaskDetail.vue";
import TaskHistory from "./latelier/client/components/tasks/TaskHistory.vue";
import TaskExport from "./latelier/client/components/tasks/TaskExport.vue";
import MenuNavigationItem from "@/components/MenuNavigationItem";
import HelperDialog from "@/components/HelperDialog";
// import HelpTextDashboard from '../public/help/dashboard/dashboard.txt'
// import HelpVideoDashboard from '../public/help/dashboard/dashboard.mp4'

export default {
  components: {
    HelperDialog,
    MenuNavigationItem,
    UserSetting,
    UserApprove,
    TaskDetail,
    TaskHistory,
    TaskExport,
  },
  data() {
    return {
      names: [
        'accountingList',
        'accountingCategory',
        'contractCategory',
        'contractList',
        'customerCategory',
        'customerList',
        'dashboard',
        'allMessage',
        'draftMessage',
        'myMessage',
        'projectCategory',
        'projectList',
        'bankAccounts',
        'funds',
        'headlines',
        'organizationalPosition',
        'organizationalUnit',
        'person',
        'andikator',
        'archive',
        'favorite',
        'templateManager',
        'tasks'
      ],
      showUserSettingDialog: false,
      showUserApproveDialog: false,
      snackbar: false,
      activeGroup: null,
      changebackgroundColor: false,
      openTabs: [],
      drawer: true,
      mini: false,
      closeOnClick: false,
      background: "",
      items: [],
      icons: [
        {icon: "mdi-pencil"},
        {icon: "mdi-pencil"},
        {icon: "mdi-clock-alert-outline"},
        {icon: "mdi-account-check"},
        {icon: "mdi-card-account-phone"},
        {icon: "mdi-purse"},
        {icon: "mdi-account-cash"},
        {icon: "mdi-texture-box"},
        {icon: "mdi-face-agent"},
        {icon: "mdi-cloud-upload"},
        {icon: "mdi-cloud-upload"},
        {icon: "mdi-cloud-upload"},
        {icon: "mdi-cloud-upload"},
        {icon: "mdi-cogs"},
      ],
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      cruds: [
        ["Create", "mdi-plus-outline"],
        ["Read", "mdi-file-outline"],
        ["Update", "mdi-update"],
        ["Delete", "mdi-delete"],
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getFileUrl",
      "getBaseUrl",
      "getColor",
      "getMessage",
      "getMenu",
    ]),
    setColor() {
      return this.$store.state.backgroundApp;
    },
    getUserProfile() {
      return this.$store.state.CurrentUser.pic;
    },
    hasToken() {
      return this.$store.state.token;
    },
  },
  created() {
    this.$store.dispatch("setToken", localStorage.getItem("token"));
    this.$store.state.token = localStorage.getItem("token");
    this.$store.state.CurrentUser.userId = localStorage.getItem("userId");
    this.$store.state.CurrentUser.username = localStorage.getItem("username");
    this.$store.state.CurrentUser.name = localStorage.getItem("name");
    this.$store.state.CurrentUser.mobile = localStorage.getItem("mobile");
    this.$store.state.CurrentUser.permissions =
        localStorage.getItem("permissions");
    this.$store.state.CurrentUser.pic = localStorage.getItem("pic")
        ? this.getFileUrl + "media/userpic/" + localStorage.getItem("pic")
        : "https://randomuser.me/api/portraits/men/85.jpg";

  },
  mounted() {
    this.checkUser();
    if (this.$store.state.token) this.getList();

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setMessage") {
        if (state.message.message != "") {
          this.snackbar = true;
        }
      }
    });
    this.checkHelper()
  },
  methods: {
    showHelp(txt) {
      this.$helperDialog.showHelper({
        url: txt + '.txt',
        video: txt + '.mp4'
      })
    },
    // بررسی helper
    checkHelper() {
      const lowerCaseRoute = this.$route.name.toLowerCase();
      this.names.forEach(name => {
        console.log(name.toLowerCase());
        if (name.toLowerCase() === lowerCaseRoute) {

          switch (lowerCaseRoute) {
            case 'accountinglist':
              this.checkUser('/help/accounting/accountingList/accountingList', 'accountingList')
              break;
            case 'accountingcategory':
              this.checkUser('/help/accounting/accountingCategory/accountingCategory', 'accountingCategory')
              break;
            case 'contractcategory':
              this.checkUser('/help/contract/contractCategory/contractCategory', 'contractCategory')
              break
            case  'contractlist' :
              this.checkUser('/help/contract/contractList/contractList', 'contractList')
              break
            case 'customercategory' :
              this.checkUser('/help/customer/customerCategory/customerCategory', 'customerCategory')
              break
            case 'customerlist' :
              this.checkUser('/help/customer/customerList/customerList', 'customerList')
              break
            case 'dashboard' :
              this.checkUser('/help/dashboard/dashboard', 'dashboard')
              break
            case 'allmessage' :
              this.checkUser('/help/message/allMessage/allMessage', 'allMessage')
              break
            case 'draftmessage' :
              this.checkUser('/help/message/draftMessage/draftMessage', 'draftMessage')
              break
            case 'mymessage' :
              this.checkUser('/help/message/myMessage/myMessage', 'myMessage')
              break
            case 'projectcategory' :
              this.checkUser('/help/project/projectCategory/projectCategory', 'projectCategory')
              break
            case 'projectlist' :
              this.checkUser('/help/project/projectList/projectList', 'projectList')
              break
            case 'bankaccounts' :
              this.checkUser('/help/settingApp/accountingSystem/bankAccounts/bankAccounts', 'bankAccounts')
              break
            case 'funds' :
              this.checkUser('/help/settingApp/accountingSystem/funds/funds', 'funds')
              break
            case 'headlines' :
              this.checkUser('/help/settingApp/accountingSystem/headlines/headlines', 'headlines')
              break
            case 'organizationalposition' :
              this.checkUser('/help/settingApp/basicInformation/organizationalPosition/organizationalPosition', 'organizationalPosition')
              break
            case 'organizationalunit' :
              this.checkUser('/help/settingApp/basicInformation/organizationalUnit/organizationalUnit', 'organizationalUnit')
              break
            case 'person' :
              this.checkUser('/help/settingApp/basicInformation/person/person', 'person')
              break
            case 'andikator' :
              this.checkUser('/help/settingApp/basicInformationOfficeAutomation/andikator/andikator', 'andikator')
              break
            case 'archive' :
              this.checkUser('/help/settingApp/basicInformationOfficeAutomation/archive/archive', 'archive')
              break
            case 'favorite' :
              this.checkUser('/help/settingApp/basicInformationOfficeAutomation/favorite/favorite', 'favorite')
              break
            case 'templatemanager' :
              this.checkUser('/help/settingApp/basicInformationOfficeAutomation/templateManager/templateManager', 'templateManager')
              break
            case 'tasks' :
              this.checkUser('', '')
              break
            default:
              return
          }
        }
      });
    },
    // بررسی ورود کاربر هر ۶ ماه
    checkUser(url, cookieName) {
      if (!url) url = '/help/help'
      const lastVisit = Cookies.get(cookieName ? cookieName : 'lastVisit');
      const today = new Date();

      if (lastVisit) {
        const lastVisitDate = new Date(lastVisit);
        const differenceInMs = today - lastVisitDate;
        const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

        // اگر 180 روز (۶ ماه) گذشته باشد
        if (differenceInDays >= 180) {
          Cookies.set(cookieName ? cookieName : 'lastVisit', today.toUTCString(), {expires: 180}); // بروزرسانی تاریخ بازدید

          // نمایش پیام آموزش به کاربر
          this.$helperDialog.showHelper({
            url: url + '.txt',
            video: url + '.mp4'
          })
        }
      } else {
        // اولین ورود کاربر
        Cookies.set(cookieName ? cookieName : 'lastVisit', today.toUTCString(), {expires: 180}); // تنظیم تاریخ بازدید
        // نمایش پیام آموزش به کاربر
        this.$helperDialog.showHelper({
          url: url + '.txt',
          video: url + '.mp4'
        })
      }
    },
    getList() {
      this.$store.dispatch("sendListMenu", {url: "menu/list/"});
    },
    removeToken() {
      this.$store.dispatch("logout");
      localStorage.removeItem("tenantFullName");
      this.$router.push({name: "Login"}).catch(() => {
      });
      this.$store.commit("setMenu", "");
    },
    onNotificationClick(data) {
      this.$store.commit("removeNotifications", data);
      if (data.url) {
        this.$router.push(data.url).catch(() => {
        });
      }
    },
    initHelp() {
      this.$helperDialog.showHelper({
        url: '/help/help.txt',
        video: '/help/help.mp4'
      })
    },
    dashboardHelp() {
      this.$helperDialog.showHelper({
        url: '/help/dashboard/dashboard.txt',
        video: '/help/dashboard/dashboard.mp4'
      })
    },
  },
  watch: {
    setColor() {
      this.changebackgroundColor = true;
    },
    mini() {
      // this.$store.commit('setMini' , this.mini);
    },
  },
  // Register your listener here.
  sockets: {
    // Equivalent of
    // signalrHubConnection.on('someEvent', (data) => this.someActionWithData(data))
    Notification(data) {
      this.$store.commit("setMessage", {
        message: data.title,
        color: "success",
        timeOut: 3000,
      });
      this.$store.commit("addNotifications", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.main-scroll {
  overflow: scroll;
  height: 100vh;
}

body {
  background: #eeeeee;
}

.border {
  border: 1px solid black;
}

.round {
  border-top-right-radius: 30px !important;
  // border-top-left-radius: 30px!important;
}

// .a {
//   background-image: url("./assets/image/sidbarcover.png") !important;
//   background-repeat: no-repeat !important;
//   background-size: 100% 250px !important;
// }
.color {
  background: transparent;
}

* {
  font-family: Vazirmatn, sans-serif !important;
}

.my-custom-class .v-list-item__content {
  width: 200px;
}

.v-treeview-node--click {
  color: red !important;
}

.changeColor {
  color: rgb(226, 73, 73) !important;
}

.search ::placeholder {
  font-size: 0.8rem !important;
  color: white !important;
}

.font {
  // font-size: 0.85rem!important;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.card-one {
  transition: 1s;
}

.card-one:hover {
  z-index: 1;
  transform: scale(1.1);
}

// .custom-link .v-list-item--active .v-list-item__title {
//   color: red; /* رنگ مورد نظر را تغییر دهید */
// }
// .v-list .v-list-item--active .v-icon{
//     color: green!important;
// }
// .v-list .v-list-item--active .v-icon{
//    color: green!important;
// }

.v-application .primary--text {
  color: #101111 !important;
  caret-color: #202020 !important;
}

.v-application--is-rtl .menu .v-list-item__icon:first-child {
  margin-left: 4px;
}
</style>
<style>
#main-menu .v-list .v-list-item--active {
  color: #b5b5b5;
}
</style>