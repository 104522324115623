<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card v-if="dialog">
      <v-card-actions>
        <v-btn color="error" icon @click="dialog = !dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-title>
        آموزش نرم‌افزار
      </v-card-title>
      <v-card-text>
        {{text}}
      </v-card-text>
      <v-card-text>
        <video style="width: 100%" controls>
          <source :src="video" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HelperDialog",
  data(){
    return{
      dialog:false,
      video:null,
      text:null,
    }
  },
  created() {
    console.log(this.$route.name);
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showHelper') {
        this.text = state.helperDialog.text;
        this.video = state.helperDialog.video;
        this.dialog = true;
      }
    })
  }
}
</script>