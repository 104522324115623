<template>
  <v-row>
<!--    <v-col v-if="subject_id === 90" cols="12" lg="4" md="6">-->
<!--      <date-picker-->
<!--          v-model="formMessage.requestDatetime"-->
<!--          format="YYYY-MM-DDTHH:mm:ss"-->
<!--          display-format="jYYYY/jMM/jDD"-->
<!--          label="تاریخ درخواست"-->
<!--          :clearable="true"-->
<!--          :autoSubmit="true"-->
<!--      />-->
<!--    </v-col>-->
    <v-col v-if="showStartDatetime" cols="12" lg="4" md="6">
      <date-picker
          v-model="formMessage.startDatetime"
          format="YYYY-MM-DDTHH:mm:ss"
          :display-format="displayStartTimeFormat"
          label="تاریخ شروع"
          :clearable="true"
          :autoSubmit="true"
          :type="needsTime"
      />
    </v-col>
    <v-col v-if="showEndDatetime" cols="12" lg="4" md="6">
      <date-picker
          v-model="formMessage.endDatetime"
          format="YYYY-MM-DDTHH:mm:ss"
          :display-format="displayStartTimeFormat"
          label="تاریخ پایان"
          :clearable="true"
          :autoSubmit="true"
          :type="needsTime"
      />
    </v-col>
    <v-col v-if="showCount" cols="12" lg="4" md="6">
      <v-text-field
          v-model="formMessage.count"
          type="number"
          outlined
          dense
          label="تعداد"
          hide-details
      ></v-text-field>
    </v-col>
    <v-col v-if="showPrice" cols="12" lg="4" md="6">
      <v-text-field
          v-model="formMessage.price"
          type="number"
          outlined
          dense
          label="مبلغ"
          hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="12" v-if="showAdditionalDescription">
      <v-textarea
          solo
          v-model="formMessage.additionalData.additionalDescription"
          :label="additionalDescriptionLabel"
      ></v-textarea>
    </v-col>
    <v-col cols="12" lg="4" md="6" v-if="showAdditionalChoiceType">
      <v-select
          label="نوع"
          hide-details
          outlined
          :items="additionalChoiceType"
          item-text="title"
          item-value="value"
          dense
          v-model="formMessage.additionalData.additionalChoiceType"
      >
      </v-select>
    </v-col>
    <v-col cols="12">
      <v-textarea
          v-model="formMessage.description"
          solo
          label="توضیحات"
      ></v-textarea>
    </v-col>
  </v-row>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import moment from "moment";

export default {
  name: "HandleSubjectFormMessage",
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  props: {
    subject_id: Number,
    formMessage: {
      type: Object,
      default: {
        id: null,
        formType: null,
        requestDatetime: moment().format("YYYY-MM-DDThh:mm:ss"),
        startDatetime: moment().format("YYYY-MM-DDThh:mm:ss"),
        endDatetime: moment().format("YYYY-MM-DDThh:mm:ss"),
        count: null,
        description: null,
        price: null,
        additionalData: {},
      }
    }
  },
  model: {
    prop: 'formMessage',
    event: 'change'
  },
  data() {
    return {
      additionalChoiceType: [
        {
          title: "استحقاقی",
          value: 10,
        },
        {
          title: "استعلاجی",
          value: 20,
        },
        {
          title: "بدون حقوق",
          value: 30,
        },
      ],
    }
  },
  watch: {
    formMessage() {
      this.$emit('change', this.formMessage)
    }
  },
  computed: {
    displayStartTimeFormat() {
      return this.needsTime == "datetime"
          ? "HH:mm jYYYY/jMM/jDD"
          : "jYYYY/jMM/jDD";
    },
    needsTime() {
      if ([10, 30, 90].includes(this.subject_id)) return "datetime";
      else return "date";
    },
    showEndDatetime() {
      return ([20, 40, 60, 90].includes(this.subject_id))
    },
    showStartDatetime(){
      return (this.subject_id !== 70 && this.subject_id !== 80 )
    },
    showCount() {
      return ([10, 20, 30, 40, 50, 60, 100].includes(this.subject_id))
    },
    showPrice() {
      return ([70, 80].includes(this.subject_id));
    },
    showAdditionalDescription() {
      return ([30, 40, 50, 60, 70, 80, 90, 100, 110].includes(this.subject_id));
    },
    additionalDescriptionLabel() {
      if ([30, 40].includes(this.subject_id)) return "علت ماموریت";
      else if ([50, 60, 70, 80, 90].includes(this.subject_id)) return "علت";
      else if (this.subject_id === 100) return "شرح وضایف";
      else if (this.subject_id === 110) return "شرح خدمات";
      return "";
    },
    showAdditionalChoiceType() {
      return ([10, 20].includes(this.subject_id));
    },
  }
}
</script>

<style scoped>

</style>