import { render, staticRenderFns } from "./Task.vue?vue&type=template&id=066f63f8&scoped=true"
import script from "./Task.vue?vue&type=script&lang=js"
export * from "./Task.vue?vue&type=script&lang=js"
import style0 from "./Task.vue?vue&type=style&index=0&id=066f63f8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066f63f8",
  null
  
)

export default component.exports