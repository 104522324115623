<template>
  <div>
    <!-- latelier -->
    <template v-if="isConnected">
      <task-history
        v-model="showTaskHistory"
        :task-id="selectedTask ? selectedTask._id : 0"
      />
      <task-export
        v-model="showTaskExport"
        :task-id="selectedTask ? selectedTask._id : 0"
      />
      <!-- <top-bar :dense="false" /> -->
      <v-navigation-drawer
        id="right-drawer"
        v-model="showTaskDetail"
        class="elevation-16"
        fixed
        left
        :width="rightDrawerWidth"
      >
        <task-detail
          :key="showTaskDetail"
          :task-id="selectedTask ? selectedTask.id : 0"
          :task-object="selectedTask"
        />
      </v-navigation-drawer>
    </template>
    <!-- latelier -->
    <div class="project px-2">
      <div v-if="!currentProject">
        <v-progress-linear indeterminate />
      </div>
      <div v-else class="project-wrapper">
        <div class="container-wrapper" :style="getBackgroundUrl(currentUser)">
          <!-- <project-toolbar :user="currentUser" :project="currentProject" /> -->
          <kanban
            ref="container"
            class="kanban-container flex1"
            :project-id="projectId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { Projects } from "/imports/api/projects/projects.js";
//import { Tasks } from "/imports/api/tasks/tasks.js";
import axios from "axios";
import BackgroundMixin from "../../../imports/ui/mixins/BackgroundMixin.js";
import debounce from "lodash/debounce";
import { mapState, mapGetters } from "vuex";
import Kanban from "@/latelier/client/components/projects/Kanban.vue";
import TaskDetail from "@/latelier/client/components/tasks/TaskDetail.vue";
import TaskExport from "@/latelier/client/components/tasks/TaskExport.vue";
import TaskHistory from "@/latelier/client/components/tasks/TaskHistory.vue";

export default {
  components: { Kanban, TaskDetail, TaskExport, TaskHistory },
  mixins: [BackgroundMixin],
  props: {
    projectId: {
      default: 0,
    },
    taskId: {
      default: 0,
    },
  },
  data() {
    return {
      savedProjectName: "",
      editProjectName: false,
      debouncedFilter: "",
      showDeleteTaskDialog: false,

      Tasks: [],
    };
  },
  computed: {
    ...mapGetters(["getBaseUrl", "getTaskItems"]),
    ...mapState("project", ["currentProject"]),
    ...mapState(["currentUser", "showTaskDetail", "selectedTask"]),
    ...mapState([
      "currentUser",
      "notifyMessage",
      "windowTitle",
      "showTaskDetailFullscreen",
    ]),
    ...mapGetters(["isTaskDetailShown", "isConnected"]),
    showTaskDetail: {
      get() {
        return this.$store.state.showTaskDetail;
      },
      set(value) {
        this.$store.dispatch("showTaskDetail", value);
        if (!value) {
          this.$store.dispatch("selectTask", {});
          this.$router
            .push({
              name: "Tasks",
            })
            .catch(() => {});
        }
      },
    },
    showTaskHistory: {
      get() {
        return this.$store.state.showTaskHistory;
      },
      set(value) {
        this.$store.dispatch("showTaskHistory", value);
      },
    },
    showTaskExport: {
      get() {
        return this.$store.state.showTaskExport;
      },
      set(value) {
        this.$store.dispatch("showTaskExport", value);
      },
    },
    rightDrawerWidth() {
      if (this.showTaskDetailFullscreen) {
        return "100%";
      }
      return 600;
    },
  },
  meteor: {
    // Subscriptions
    $subscribe: {
      project() {
        return [this.projectId];
      },
    },
    project() {
      if (this.taskId) {
        this.selectTask(this.taskId);
      }
      const project = Projects.findOne();
      if (project) {
        this.$store.dispatch("project/setCurrentProject", project);
        this.$store.dispatch("setWindowTitle", project.name);
      }
      return project;
    },
  },
  watch: {
    taskId: {
      immediate: true,
      handler(taskId) {
        if (taskId) {
          this.selectTask(taskId);
        }
      },
    },
    projectId: {
      handler() {
        this.$store.dispatch("project/setCurrentProjectId", this.projectId);
      },
    },
    "$store.state.taskItems"() {
      if (this.taskId) {
        this.selectTask(this.taskId);
      }
    },
    showTaskDetail(now, prev) {
      // if (!now && prev) {
      //   this.$store.dispatch("selectTask", null);
      //   this.$router.push({
      //     name: "Tasks",
      //     params: {
      //       organizationId: this.organizationId,
      //       projectId: this.projectId,
      //     },
      //   });
      // }
    },
  },
  mounted() {
    this.getData();

    this.$store.dispatch("project/setCurrentProjectId", this.projectId);
    this.$events.listen("delete-task", (task) => {
      this.deleteTask(task);
    });
  },
  created() {
    this.debouncedFilter = debounce((val) => {
      this.$events.fire("filter-tasks", val);
    }, 400);
  },
  beforeDestroy() {
    // this.$events.off("delete-task");
    // this.$store.dispatch("project/setCurrentProjectId", null);
    // this.$store.dispatch("selectTask", null);
    // this.$store.dispatch("showTaskDetail", false);
  },
  methods: {
    getData() {
      this.$store.dispatch("refereshTaskList");
      this.$store.dispatch("refereshTaskItems");
    },

    selectTask(taskId) {
      const selectedTask = this.getTaskItems.find((x) => x.id == taskId);
      if (selectedTask) {
        this.$store.dispatch("selectTask", selectedTask);
        this.$store.dispatch("showTaskDetail", true);
      }
    },
    deleteTask(task) {
      this.$confirm(this.$t("Do you really want to delete this task?"), {
        title: this.$t("Confirm"),
        cancelText: this.$t("Cancel"),
        confirmText: this.$t("Move to trash"),
      }).then((res) => {
        if (res) {
          //Meteor.call("tasks.remove", task._id);
          this.$store.dispatch("showTaskDetail", false);
          axios
            .delete(`${this.getBaseUrl}/taskitem/delete/`, {
              data: {
                Id: task.id,
                Language: 0,
              },
            })
            .then((data) => {
              if (data.data.status == 1) {
                this.$store.dispatch("removeTaskItem", { id: task.id });
                this.$store.commit("setMessage", {
                  message: "درخواست با موفقیت انجام شد",
                  color: "success",
                  timeOut: 2000,
                });
              } else if (data.data.status == 0) {
                this.$store.commit("setMessage", {
                  message: data.data.message,
                  color: "red",
                  timeOut: 2000,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {});
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../imports/ui/styles/mixins/scrollbar";

@include scrollbar;

.project {
  display: flex;
  min-height: 0;
  height: 100%;
  flex-direction: column;
  position: relative;
  flex: 1;
  height: 100vh;
  background-image: linear-gradient(135deg, #ce9ffc 10%, #7367f0 100%);
}

.project-wrapper {
  display: flex;
  min-height: 0;
  flex-direction: column;
  flex: 1;
  position: relative;
}

@media (max-width: 600px) {
  .kanban-container {
    margin: 4px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (min-width: 601px) {
  .kanban-container {
    margin: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: scroll;
    padding-left: 4px;
    height: 100%;
  }
}

.kanban-container {
  margin-top: 1rem;
}

.container-wrapper {
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    min-height: 100vh;
  }
}
</style>
