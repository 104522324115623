<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" v-if="!initLoading">
        <v-app-bar dense flat color="grey" tile>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn class="ml-1" color="white" v-on="on">
                <v-icon small>mdi-email</v-icon>
                <span>نامه جدید</span>
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item
                    v-if="CurrentUser.permissions.includes('CreateInternalMessage')"
                    @click="$router.push({name: 'Letter',
                                params: {
                                  create: true,
                                  pagetype: 'AllMessage',
                                  messagetype: 4,
                                  id: 0,
                                }
                              })">
                  <span>نامه داخلی</span>
                </v-list-item>
                <v-list-item
                    v-if="CurrentUser.permissions.includes('CreateImportMessage')"
                    @click="$router.push({
                                name: 'Letter',
                                params: {
                                  create: true,
                                  pagetype: 'AllMessage',
                                  messagetype: 5,
                                  id: 0,
                                }
                              })">
                  <span>نامه وارده</span>
                </v-list-item>
                <v-list-item
                    v-if="CurrentUser.permissions.includes('CreateExportMessage')"
                    @click="$router.push({
                                name: 'Letter',
                                params: {
                                  create: true,
                                  pagetype: 'AllMessage',
                                  messagetype: 6,
                                  id: 0,
                                }
                              })">
                  <span>نامه صادره</span>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-btn v-if="message_id"
                 color="white"
                 class="ml-1"
                 @click="forwardDialog = true;forwardOrForwardDraft = 3"
          >
            <v-icon small>mdi-border-color</v-icon>
            پاسخ
          </v-btn>
          <v-btn v-if="message_id"
                 color="white"
                 class="ml-1"
                 @click="forwardDialog = true;forwardOrForwardDraft = 1;getPersonList()"
          >
            <v-icon small>mdi-undo</v-icon>
            ارجاع
          </v-btn>
          <v-menu v-if="archiveItems.length" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn class="ml-1" color="white" v-on="on"
              >
                <v-icon small>mdi-email</v-icon>
                بایگانی
                <v-icon
                >mdi-chevron-down
                </v-icon
                >
              </v-btn
              >
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item
                    v-for="(item, index) in archiveItems"
                    :key="index"
                    @click="sendToArchive(item)"
                >
                  <v-list-item-title>{{
                      item.archiveTitle
                    }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-menu v-if="favoriteItems.length" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn class="ml-1" color="white" v-on="on"
              >
                <v-icon small>mdi-email</v-icon>
                علاقه مندی
                <v-icon
                >mdi-chevron-down
                </v-icon
                >
              </v-btn
              >
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item
                    v-for="(item, index) in favoriteItems"
                    :key="index"
                    @click="sendToFavorite(item)"
                >
                  <v-list-item-title>{{
                      item.favoriteTitle
                    }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-menu v-if="templateItems.length" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn class="ml-1" color="white" v-on="on">
                <v-icon small>mdi-printer</v-icon>
                چاپ
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item
                    v-for="(item, index) in templateItems"
                    :key="index"
                >
                  <v-list-item-title>
                    <a
                        :href="
                                baseURL +
                                '/message/docx?templateId=' +
                                item.templateManagerId +
                                '&id=' +
                                dataLetter?.messageId +
                                '&token=' +
                                token
                              "
                    >
                      {{ item.templateName }}
                    </a>
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-btn v-if="message_id"
                 color="error"
                 @click="removeLetterDialog = !removeLetterDialog"
          >
            <v-icon small>mdi-close</v-icon>
            حذف
          </v-btn>
        </v-app-bar>
      </v-col>
      <v-col cols="12" md="9" lg="10" v-if="!initLoading">
        <v-card>
          <v-tabs v-model="tab">
            <v-tab v-if="message_id || dataLetter.isSend">مشاهده نامه</v-tab>
            <v-tab v-else>فرم درخواست</v-tab>
            <v-tab>
              <v-badge
                  color="teal"
                  :value="attachments.length"
                  :content="attachments.length"
              >
                پیوست
              </v-badge>
            </v-tab>
            <v-tab>
              <v-badge
                  color="teal"
                  :value="relatedMessages.length"
                  :content="relatedMessages.length"
              >
                نامه‌های مرتبط
              </v-badge>
            </v-tab>
            <v-tab v-if="message_id">
              <v-badge
                  color="teal"
                  :value="allForwardedMessage.length"
                  :content="allForwardedMessage.length"
              >
                ارجاعات
              </v-badge>
            </v-tab>
            <v-tab v-if="message_id">
              <v-badge
                  color="teal"
                  :value="messageEvent.length"
                  :content="messageEvent.length"
              >
                رویداد نامه
              </v-badge>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="pa-6">
            <v-tab-item v-if="message_id || dataLetter.isSend">
              <v-card color="grey lighten-4">
                <v-card-title class="text-h3 justify-center">
                  {{ dataLetter?.subject }}
                </v-card-title>
                <v-card-text>
                  <v-row justify="center">
                    <v-col cols="7" >
                      <v-list >
                        <v-list-item>
                          <v-list-item-content>تاریخ:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ `${dataLetter?.createdDate}` }}
                          </v-list-item-content>
                        </v-list-item> <v-divider/>
                        <v-list-item>
                          <v-list-item-content>شماره:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ dataLetter?.andikatorPrefix + " " + dataLetter?.number }}
                          </v-list-item-content>
                        </v-list-item> <v-divider/>
                        <v-list-item>
                          <v-list-item-content>پیوست:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ dataLetter?.hasMessageAttachment ? "دارد" : "ندارد" }}
                          </v-list-item-content>
                        </v-list-item> <v-divider/>
                        <v-list-item>
                          <v-list-item-content>درخواست کننده:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ dataLetter?.createdUser }}
                          </v-list-item-content>
                        </v-list-item> <v-divider/>
                        <v-list-item>
                          <v-list-item-content>اندیکاتور:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ dataLetter?.andikatorTitle }}
                          </v-list-item-content>
                        </v-list-item> <v-divider/>
                        <v-list-item>
                          <v-list-item-content>پروژه:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ dataLetter?.projectTitle }}
                          </v-list-item-content>
                        </v-list-item> <v-divider/>
                        <v-list-item>
                          <v-list-item-content>گیرندگان:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ dataLetter?.messageTo }}
                          </v-list-item-content>
                        </v-list-item> <v-divider/>
                        <v-list-item>
                          <v-list-item-content>رونوشت:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ dataLetter?.messageCc }}
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>

                    <v-col cols="7">
                      <h4 class="mb-2">توضیحات درخواست :</h4>
                      <p v-html="dataLetter.messageBody"></p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item v-else>
              <v-form ref="validateForm">
                <v-row>
                  <v-col cols="12" lg="4">
                    <v-autocomplete outlined
                                    dense
                                    label="انتخاب فرم درخواست"
                                    v-model="dataLetter.subject"
                                    :items="subjectItems"
                                    item-text="title"
                                    item-value="value"
                                    :rules="[v => !!v || 'الزامی است']"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="4" md="6">
                    <v-autocomplete outlined
                                    dense
                                    label="اندیکاتور"
                                    :loading="getAndikatorListLoading"
                                    :items="andikatorItems"
                                    hide-no-data
                                    v-model="dataLetter.andikatorFk"
                                    item-text="andikatorTitle"
                                    item-value="andikatorId"
                                    :rules="[v => !!v || 'الزامی است']"
                                    @click="getAndikatorList()"/>
                  </v-col>
                  <v-col cols="12" lg="4" md="6">
                    <v-select :items="answerRequestItems"
                              item-text="title"
                              item-value="value"
                              hide-no-data
                              outlined
                              dense
                              v-model="dataLetter.answerRequest"
                              label="نیاز به پاسخ">

                    </v-select>

                  </v-col>
                  <v-col cols="12" lg="4" md="6">
                    <v-autocomplete outlined
                                    dense
                                    label="پروژه"
                                    :loading="getProjectListLoading"
                                    :items="projectItems"
                                    hide-no-data
                                    v-model="dataLetter.projectFk"
                                    item-text="projectTitle"
                                    item-value="projectId"
                                    @click="getProjectList()"/>
                  </v-col>
                  <v-col cols="12" lg="4" md="6">
                    <v-autocomplete v-model="dataLetter.SelectedTo"
                                    label="گیرندگان"
                                    :loading="getPersonListLoading"
                                    :items="personItems"
                                    :item-text="(item) =>`${item.firstName} ${item.lastName}`"
                                    item-value="id"
                                    outlined
                                    dense
                                    hide-no-data
                                    multiple
                                    clearable
                                    @click="getPersonList()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="4" md="6">
                    <v-autocomplete v-model="dataLetter.selectedCC"
                                    label="رونوشت"
                                    :items="personItems"
                                    :loading="getPersonListLoading"
                                    :item-text="(item) =>`${item.firstName} ${item.lastName}`"
                                    item-value="id"
                                    outlined
                                    dense
                                    hide-no-data
                                    multiple
                                    clearable
                                    @click="getPersonList()"/>
                  </v-col>
                  <v-col cols="12" v-if="dataLetter?.subject">
                    <HandleSubjectFormMessage v-model="dataLetter.formMessage" :subject_id="dataLetter.subject"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                  :headers="attachmentHeaders"
                  :items="attachments"
                  hide-default-footer
              >
                <template v-slot:item.fullName="{ item }">
                  {{ item.fullName }}
                </template>
                <template v-slot:item.btn="{ item }">
                  <a
                      :href=" baseURL + '/message/download?AttachmentId=' + item.msg.messageAttachmentId + '&token=' + token "
                  >
                    <v-icon> mdi-download</v-icon>
                  </a>
                </template>
              </v-data-table>
              <DropFile v-if="!message_id && !dataLetter.isSend"
                        ref="dropfile"
                        @fileChanged="onFileChanged"
              />
            </v-tab-item>
            <v-tab-item>
              <v-data-table class="mb-4"
                            :headers="relatedMessageHeaders"
                            :items="relatedMessages"
                            hide-default-footer
              >
                <template v-slot:item.description="{ item }">
                  <v-text-field
                      class="mt-4"
                      outlined
                      dense
                      v-model="item.description"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.createdDate`]="{ item }">
                  <div>{{
                      toPersianDateTime(item.createdDate)
                    }}
                  </div>
                </template>
                <template v-slot:item.follow="{ item }">
                  <div>{{
                      item.follow == true ? "پیرو" : "ارجاء"
                    }}
                  </div>
                </template>
                <template v-slot:item.operation="{ item }">
                  <router-link
                      :to="{
                                        name: 'Letter',
                                        params: {
                                          create: false,
                                          pagetype: 'AllMyMessage',
                                          messagetype: 4,
                                          id: item.messageId,
                                        },
                                      }"
                  >
                    <span> مشاهده </span>
                  </router-link>
                  <v-tooltip
                      bottom
                  >
                    <template
                        v-slot:activator="{ on, attrs }"
                    >
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="error"
                          @click="deleteRelatedMessageItem(item)"
                          class="mr-2"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>حذف</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <v-btn v-if="!dataLetter.isSend" @click="openRelatedDialog"
              >ایجاد نامه مرتبط
              </v-btn>
            </v-tab-item>
            <v-tab-item v-if="message_id">
              <div>
                <span>متن نامه اصلی : </span>
                <p
                    v-html="dataLetter.messageBody"
                    class="pa-6"
                ></p>
              </div>
              <v-divider/>
              <v-data-table
                  :headers="forwardedMessageHeaders"
                  :items="allForwardedMessage || []"
                  hide-default-footer
              >
                <template v-slot:item.description="{ item }">
                  <v-text-field
                      class="mt-4"
                      outlined
                      dense
                      v-model="item.description"
                  ></v-text-field>
                </template>
                <template
                    v-slot:[`item.createdDate`]="{ item }"
                >
                                    <span>{{
                                        toPersianDateTime(item.createdDate)
                                      }}</span>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item v-if="message_id">
              <v-data-table
                  :headers="messageEventHeaders"
                  :items="messageEvent"
              >
                <template
                    v-slot:[`item.createdDate`]="{ item }"
                >
                                    <span>{{
                                        toPersianDateTime(item.createdDate)
                                      }}</span>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions>
            <v-btn
                v-if="!message_id"
                class="ma-1"
                compact
                color="success"
                @click="sendMessage"
            >
              <v-icon small>mdi-undo</v-icon>
              ارسال
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="9" lg="10" v-else>
        <v-skeleton-loader
            type="  list-item-three-line, card-heading, image, actions"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3" lg="2">
        <MenuLeft/>
      </v-col>
    </v-row>

    <!-- دیالوگ تایید حذف -->
    <v-dialog v-model="removeLetterDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
        <v-card-actions class="justify-center">

          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="removeLetterDialog = false"
          >لغو
          </v-btn
          >
          <v-btn color="error" variant="text" @click="deleteItemConfirm"
          >بله
          </v-btn
          >

        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- دیالوگ ارجاعات -->
    <v-dialog v-model="forwardDialog" max-width="800">
      <v-card>
        <v-card-title>
          {{ forwardOrForwardDraft === 3 ? "پاسخ" : "ارجاع پیام" }}
        </v-card-title>
        <v-card-text>
          <v-autocomplete
              class="mb-4"
              v-if="forwardOrForwardDraft !== 3"
              v-model="forwardModel.selectedTo"
              :loading="getPersonListLoading"
              :items="personItems"
              :item-text="(item) => `${item.firstName} ${item.lastName}`"
              item-value="id"
              outlined
              hide-details
              dense
              chips
              small-chips
              label="گیرندگان"
              :multiple="forwardOrForwardDraft === 1"
          ></v-autocomplete>
          <v-textarea
              hide-details
              outlined
              v-model="forwardModel.forwardMessage"
          ></v-textarea>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="forwardMessage()" color="success">
            {{ forwardOrForwardDraft === 3 ? "پاسخ" : "ارجاع" }}
          </v-btn>
          <v-btn @click="forwardDialog = !forwardDialog">لغو</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- دیالوگ نامه های مرتبط -->
    <v-dialog value="true" v-model="relatedDialog" max-width="800" persistent>
      <RelatedMessageList
          :headers="relatedListHeaders"
          :sendList="relatedListLetters"
          :loading="relatedLoading"
          @selectChanged="relatedListSelectedChanged"
          @cancle="relatedDialog = false"
          @pageChanged="relatedPageChanged"
          @search="relatedSearchChanged"
      ></RelatedMessageList>
    </v-dialog>
  </v-container>
</template>

<script>
import MenuLeft from "@/components/MenuLeft";
import axios from "axios";
import EditForm from "@/views/Forms/EditForm";
import moment from "moment";
import HandleSubjectFormMessage from "@/components/Form/HandleSubjectFormMessage";
import DropFile from "@/components/DropFile";
import {myMixin} from "../../../mixins";
import {mapState} from 'vuex';
import RelatedMessageList from "@/views/LettersManagment/RelatedMessageList";


export default {
  name: "NewRoute",
  mixins: [myMixin],
  components: {RelatedMessageList, DropFile, HandleSubjectFormMessage, EditForm, MenuLeft},
  data() {
    return {
      messagetype:4,
      baseURL: null,
      initLoading: true,
      message_id: null,
      token: localStorage.getItem("token"),
      attachments: [],
      answerRequestItems:[
        {value:true , title:"دارد"},
        {value:false , title:"ندارد"},
      ],
      attachmentHeaders: [
        {
          text: "ایجاد کننده",
          align: "start",
          value: "fullName",
        },
        {
          text: "نام",
          align: "start",
          value: "msg.fileName",
        },
        {
          text: "توضیحات",
          align: "start",
          value: "msg.fileComment",
        },
        {text: "دانلود", value: "btn", align: "center"},
      ],
      relatedMessageHeaders: [
        {text: "شماره نامه", value: "number", align: "center"},
        {text: "موضوع نامه", value: "subject", align: "center"},
        {text: "تاریخ نامه", value: "createdDate", align: "center"},
        {text: "فرستنده", value: "createdUser", align: "center"},
        {text: "نوع ارتباط", value: "follow", align: "center"},
        {text: "عملیات", value: "operation", align: "center", sortable: false},
      ],
      forwardedMessageHeaders: [
        {text: "متن", value: "forwardMessage", align: "center"},
        {text: "تاریخ", value: "createdDate", align: "center"},
        {text: "فرستنده", value: "creatorUserFullName", align: "center"},
        {text: "گیرندگان", value: "receivedUserFullName", align: "center"},
      ],
      messageEventHeaders: [
        { text: "تاریخ", value: "createdDate", align: "center" },
        { text: "رویداد", value: "eventType", align: "center" },
        { text: "توسط", value: "creatorUserFullName", align: "center" },
      ],
      tab: null,
      validateForm: false,
      subjectItems: [
        {
          title: "مرخصی ساعتی",
          value: 10,
        },
        {
          title: "مرخصی روزانه",
          value: 20,
        },
        {
          title: "ماموریت ساعتی",
          value: 30,
        },
        {
          title: "ماموریت روزانه",
          value: 40,
        },
        {
          title: "مجوز اضافه کاری روزانه",
          value: 50,
        },
        {
          title: "مجوز اضافه کاری ماهانه",
          value: 60,
        },
        {
          title: "درخواست مساعده",
          value: 70,
        },
        {
          title: "درخواست تنخواه",
          value: 80,
        },
        {
          title: "ثبت ورود و خروج فراموش شده",
          value: 90,
        },
        {
          title: "درخواست نیرو",
          value: 100,
        },
        {
          title: "درخواست خدمات",
          value: 110,
        },
        // {
        //   title: "درخواست کالا",
        //   value: 120,
        // },
        {
          title: "تحویل اموال",
          value: 130,
        },
        // {
        //   title: "درخواست کالا از انبار",
        //   value: 140,
        // },
      ],
      relatedMessages: [],
      allForwardedMessage: [],
      messageEvent: [],
      forwardOrForwardDraft: 1,
      forwardModel: {forwardMessage: "", selectedTo: []},
      relatedListHeaders: [
        {
          text: "موضوع نامه",
          value: "subject",
          sortable: false,
          align: "center",
          width: 300,
        },

        {
          text: "شماره نامه",
          value: "displayMessageNumber",
          align: "center",
          sortable: false,
          width: 100,
        },
      ],

      dataLetter: {
        subject: null,
        andikatorFk: null,
        answerRequest: false,
        projectFk: null,
        SelectedTo: [],
        selectedCC: [],
        formMessage: {
          id: null,
          formType: null,
          requestDatetime: moment(new Date()).format("YYYY-MM-DDThh:mm:ss"),
          startDatetime: moment().format("YYYY-MM-DDThh:mm:ss"),
          endDatetime: moment().format("YYYY-MM-DDThh:mm:ss"),
          count: null,
          description: null,
          price: null,
          additionalData: {},
        },
        messageBody: null,
      },

      relatedListLetters: [],
      archiveItems: [],
      templateItems: [],
      favoriteItems: [],
      andikatorItems: [],
      getAndikatorListLoading: false,
      projectItems: [],
      getProjectListLoading: false,
      personItems: [],
      getPersonListLoading: false,
      // customerItems: [],
      fileChanged: false,
      relatedLoading: false,


      // dialogs
      removeLetterDialog: false,
      forwardDialog: false,
      relatedDialog: false,

    }
  },
  methods: {
    relatedSearchChanged(search) {
      this.relatedSearch = search;
      this.getListLetters(1, 20, search);
    },
    relatedPageChanged(option) {
      this.getListLetters(
          option.page,
          option.itemsPerPage,
          this.relatedSearch
      );
    },
    relatedListSelectedChanged(items) {
      this.relatedMessages.push(...items);
      //distinct
      this.relatedMessages = this.relatedMessages.filter(function (x, i, a) {
        return a.indexOf(x) === i;
      });
      this.relatedDialog = false;
    },
    deleteRelatedMessageItem(item) {
      this.relatedMessages = this.relatedMessages.filter(function (i) {
        return i !== item;
      });
    },
    async sendToFavorite(item) {
      await axios.put(process.env.VUE_APP_API_BASE_URL + `/message/setfavorite/`, {
        MessageId: Number(this.message_id),
        FavoriteId: item.favoriteId,
        Language: 0,
        PageType: this.pagetype,
      })
          .then((res) => {
            if (res.data.status === 1) {
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
                timeOut: 3000,
              });
            } else if (res.data.status !== 1) {
              this.$store.commit("setMessage", {
                message: res.data.message,
                color: "red",
                timeOut: 3000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    async sendToArchive(item) {
      await axios.put(process.env.VUE_APP_API_BASE_URL + `/message/setarchive/`, {
        MessageId: Number(this.message_id),
        ArchiveId: item.archiveId,
        Language: 0,
        PageType: this.pagetype,
      })
          .then((res) => {
            if (res.data.status === 1) {
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
                timeOut: 3000,
              });
            } else if (res.data.status !== 1) {
              this.$store.commit("setMessage", {
                message: res.data.message,
                color: "red",
                timeOut: 3000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    async forwardMessage() {
      const data = {
        MessageId: this.message_id,
        Language: 0,
        IsSend: this.forwardOrForwardDraft === 1 ? "1" : this.forwardOrForwardDraft === 2 ? "2" : "7",
        PageType: this.pagetype,
        ForwardMessage: this.forwardModel.forwardMessage,

        //1  ارجاع
        //2 ارجاء پیش نویس
        //3 پاسخ
        //
        SelectedTo: this.forwardOrForwardDraft === 1 ? this.forwardModel.selectedTo : this.forwardOrForwardDraft === 3 ? [this.dataLetter.messageFromManFk] : [this.forwardModel.selectedTo],
        Subject: this.dataLetter.subject,
        MessageBody: this.dataLetter.messageBody,
        AndikatorFk: this.dataLetter.andikatorFk,
        MessageTypeFk: this.messagetype,
        ProjectFk: this.dataLetter.projectFk,
        MessageFromManFk: this.dataLetter.messageFromManFk,
        MessageFromUserId: this.dataLetter.messageFromUserId,
        FormMessage: {
          ...this.$refs.formMessage,
          additionalData: JSON.stringify(
              this.$refs.formMessage?.additionalData
          ),
        },
      }
      await axios.post(process.env.VUE_APP_API_BASE_URL + `/message/forward/`, {...data})
          .then((res) => {
            if (res.data.status === 1) {
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
                timeOut: 2000,
              });
              if (this.forwardOrForwardDraft === 2 || this.forwardOrForwardDraft === 7) {
                this.$router.push({
                  name: "DraftMessage",
                })
              } else {
                this.$router.push({
                  name: "MyMessage",
                })
              }
            } else {
              this.$store.commit("setMessage", {
                message: res.data.messge,
                color: "red",
                timeOut: 2000,
              });
            }
            this.forwardDialog = false;
          })
          .catch((error) => {
            console.error(error);
          });
    },
    onFileChanged(file) {
      this.fileChanged = file.length > 0;
    },
    async getAndikatorList() {
      if (this.andikatorItems && this.andikatorItems.length) {
        return;
      }
      this.getAndikatorListLoading = true
      await axios.get(process.env.VUE_APP_API_BASE_URL + '/andikator/list?Count=20&Page=1&Language=0').then(res => {
        this.andikatorItems = res.data.records
      }).catch(err => {
        console.error(err)
      })
      this.getAndikatorListLoading = false
    },
    async getProjectList() {
      if (this.projectItems && this.projectItems.length) {
        return;
      }
      this.getProjectListLoading = true
      await axios.get(process.env.VUE_APP_API_BASE_URL + '/project/list?Count=20&Page=1&Language=0').then(res => {
        this.projectItems = res.data.records
      }).catch(err => {
        console.error(err)
      })
      this.getProjectListLoading = false
    },
    async getPersonList() {
      if (this.personItems && this.personItems.length) {
        return;
      }
      this.getPersonListLoading = true
      await axios.get(process.env.VUE_APP_API_BASE_URL + '/person/list?Count=20&Page=1&Language=0').then(res => {
        this.personItems = res.data.records
      }).catch(err => {
        console.error(err)
      })
      this.getPersonListLoading = false
    },
    async sendMessage() {
      if (this.$refs.validateForm.validate()) {
        const data = {
          Language: 0,
          PageType: this.pagetype,
          txtMsgAttachment: "",
          txtRelatedMsg: "",
          IsSend: "1",
          Attachments: this.$refs.dropfile?.files,
          Subject: (this.subjectItems.find(item => item.value === this.dataLetter.subject) || {}).title,
          MessageBody: this.dataLetter.messageBody ? this.dataLetter.messageBody : this.dataLetter.formMessage.description,
          AndikatorFk: this.dataLetter?.andikatorFk,
          MessageId: this.message_id,
          MessageTypeFk: this.messagetype,
          ProjectFk: this.dataLetter.projectFk,
          ReciveMethodFk: this.dataLetter.reciveMethodFk,
          RelatedMessages: this.relatedMessages.map(i => {
            return {MessageId: i.messageId, Follow: i.follow};
          }),
          SelectedTo: this.dataLetter.SelectedTo,
          ExportCustomerAgent: this.dataLetter.exportCustomerAgent,
          SelectedCC: this.dataLetter.selectedCC,
          ImportedMsgDate: this.dataLetter.importedMsgDate
              ? moment
                  .from(this.dataLetter.importedMsgDate, "fa", "YYYY/MM/DD")
                  .locale("en")
                  .format("YYYY-MM-DDThh:mm:ss")
              : null,
          ImportedMsgNumber: this.dataLetter.importedMsgNumber,
          MessageFromManFk: this.dataLetter.messageFromManFk,
          MessageFromCustomerFk: this.dataLetter.messageFromCustomerFk,
          AnswerRequest: this.dataLetter.answerRequest,
          MessageFromUserId: this.dataLetter.messageFromUserId,
          formMessage: this.formMessage

        }
        if (!this.message_id) {
          await axios.post(process.env.VUE_APP_API_BASE_URL + `/message/save/`, {...data}, {headers: {"Content-Type": "multipart/form-data"}})
              .then((res) => {
                if (res.data.status === 1) {
                  this.$store.commit("setMessage", {
                    message: "درخواست با موفقیت انجام شد",
                    color: "success",
                    timeOut: 2000,
                  });
                  this.$router.push({
                    name: "requestFormDetail",
                    params: {
                      message_id: res.data.id,
                    },
                  })
                  window.location.reload()
                } else {
                  this.$store.commit("setMessage", {
                    message: res.data.messge,
                    color: "red",
                  });
                }
              })
              .catch((error) => {
                console.error(error);
                this.$store.commit("setMessage", {
                  message: error.message,
                  color: "red",
                  timeOut: 2000,
                });
              });
        } else {
          await axios.post(process.env.VUE_APP_API_BASE_URL + `/message/save/`, {...data}, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
              .then((res) => {
                if (res.data.status === 1) {
                  this.$store.commit("setMessage", {
                    message: "درخواست با موفقیت انجام شد",
                    color: "success",
                    timeOut: 2000,
                  });
                  this.$router.go(-1)
                } else {
                  this.$store.commit("setMessage", {
                    message: res.data.messge,
                    color: "red",
                    timeOut: 2000,
                  });
                }
              })
              .catch((err) => {
                console.error(err)
                this.$store.commit("setMessage", {
                  message: err.message,
                  color: "red",
                  timeOut: 2000,
                });
              });
        }
      }
    },
    async getLetter(message_id) {
      await axios.get(process.env.VUE_APP_API_BASE_URL + `/message/get`, {
        params: {
          Id: message_id,
          Language: 0,
          PageType: 'AllMessage',
        },
      })
          .then((res) => {
            this.allForwardedMessage = res.data.allForwardedMessage
            this.dataLetter = res.data.record.msg;
            this.dataLetter.createdDate = this.toPersianDateTime(
                res.data.record.msg.createdDate
            );
            if (res.data.record.msg.importedMsgDate)
              this.dataLetter.importedMsgDate = this.toPersianDate(
                  res.data.record.msg.importedMsgDate
              );
            //
            this.dataLetter.selectedTo = res.data.record.selectedTo;
            this.dataLetter.selectedCC = res.data.record.selectedCC;
            // this.dataLetter.selectedBcc = data.data.record.selectedBcc;
            this.dataLetter.formMessage = res.data.record.msg.formMessage;
            this.dataLetter.formMessage.additionalData = JSON.parse(
                this.dataLetter.formMessage.additionalData
            );
            this.attachments = res.data.attachment;
            this.relatedMessages = res.data.relatedMessage;
            this.allForwardedMessage = res.data.allForwardedMessage;
            this.messageEvent = res.data.messageEvent;

            if (!res.data.record.msg.firstTimeRead) this.tab = 3;

          })
          .catch((error) => {
            console.error(error);
          })
    },
    async getArchiveList() {
      await axios.get(process.env.VUE_APP_API_BASE_URL + '/archive/list/', {
        params: {
          Count: 20,
          Page: 1,
          Language: 0,
        }
      }).then(res => {
        this.archiveItems = res.data.records
        this.favoriteItems = res.data.records
      }).catch(err => {
        console.error(err)
      })
    },
    async getTemplateList() {
      await axios.get(process.env.VUE_APP_API_BASE_URL + '/template/list/', {
        params: {
          Count: 20,
          Page: 1,
          Language: 0,
        }
      }).then(res => {
        this.templateItems = res.data.records
      }).catch(err => {
        console.error(err)

      })
    },
    async deleteItemConfirm() {
      await axios.delete(process.env.VUE_APP_API_BASE_URL + `/message/delete/`, {
        data: {
          Id: this.message_id,
          Language: 0,
          TypeNumber: `${this.dataLetter.messagetype ? this.dataLetter.messagetype : this.dataLetter.messagetypeFK}`,
          PageType: this.pagetype,
          IsSend: true,
        },
      })
          .then((res) => {
            if (res.data.status === 1) {
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
                timeOut: 3000,
              });
              this.removeLetterDialog = false;
              this.$router.push({name: "MyMessage"});
            } else {
              this.$store.commit("setMessage", {
                message: res.data.message,
                color: "red",
                timeOut: 3000,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },
    async getListLetters(page, pageSize, search) {
      await axios.get(process.env.VUE_APP_API_BASE_URL + `/message/list/`, {
        params: {
          Count: pageSize,
          Page: page,
          Language: 0,
          MessageType: 0,
          Phrase: search,
          TypeNumber: "string",
          PageType: "AllMyMessage",
        },
      })
          .then((res) => {
            this.relatedListLetters = res.data;
          })
          .catch((error) => {
            console.error(error);
          })

    },
    async openRelatedDialog() {
      if (this.relatedListLetters && this.relatedListLetters.length) return;
      this.relatedLoading = true
      await this.getListLetters(1, 20, "")
      this.relatedDialog = !this.relatedDialog
      this.relatedLoading = false
    }
  },
  async created() {
    this.baseURL = process.env.VUE_APP_API_BASE_URL
    if (this.$route.params.message_id) {
      this.message_id = this.$route.params.message_id
      await this.getLetter(this.message_id)
      await this.getArchiveList()
      await this.getTemplateList()
    }
    // await this.getLetter(10647)
    this.initLoading = false
  },
  computed: {
    ...mapState({CurrentUser: state => state.CurrentUser})
  }

}
</script>

<style scoped>

</style>