<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            ref="grid"
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :items="getDomainList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            :hasLogo="true"
            @changeLogo="onChangeLogo"
            :hasAccess="false"
            :add="false"
            :edit="false"
            :remove="false"
          >
            <template v-slot:addForm>
              <v-form ref="form">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان شرکت"
                        outlined
                        dense
                        hide-details
                        v-model="addData.companyName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        :rules="nameRules"
                        label="نام"
                        outlined
                        dense
                        hide-details
                        v-model="addData.webmasterFirstname"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        :rules="nameRules"
                        label="نام خانوادگی"
                        outlined
                        dense
                        hide-details
                        v-model="addData.webmasterLastName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        :rules="numberRules"
                        label="شماره موبایل"
                        outlined
                        dense
                        hide-details
                        v-model="addData.webmasterMobileInfo"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="آدرس ایمیل"
                        outlined
                        dense
                        :hide-details="emailRules ? true : false"
                        :rules="emailRules"
                        v-model="addData.WebmasterEmail"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="ساب دامین"
                        outlined
                        dense
                        hide-details
                        v-model="addData.subDomain"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="آدرس دامین"
                        outlined
                        dense
                        hide-details
                        v-model="addData.domainAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="SSL"
                        hide-details
                        outlined
                        :items="sSLList.records"
                        item-text="value"
                        item-value="id"
                        dense
                        v-model="addData.sSLRedirect"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="WWW"
                        hide-details
                        outlined
                        :items="wWW.records"
                        item-text="value"
                        item-value="id"
                        dense
                        v-model="addData.wWWRedirect"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        label="دامین خارجی"
                        hide-details
                        dense
                        v-model="addData.externalDomain"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        label="مجوز ارسال سازمانی"
                        hide-details
                        dense
                        v-model="addData.licenseToSendMail"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        label="فعال"
                        hide-details
                        dense
                        v-model="addData.isActive"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان شرکت"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.companyName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        :rules="nameRules"
                        label="نام"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.webmasterFirstname"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        :rules="nameRules"
                        label="نام خانوادگی"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.webmasterLastName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        :rules="numberRules"
                        label="شماره موبایل"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.webmasterMobileInfo"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="آدرس ایمیل"
                        outlined
                        dense
                        :rules="emailRules"
                        hide-details
                        v-model="editData.data.webmasterEmail"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="ساب دامین"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.subDomain"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="آدرس دامین"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.domainAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="SSL"
                        hide-details
                        outlined
                        :items="sSLList.records"
                        item-text="value"
                        item-value="id"
                        dense
                        v-model="editData.data.sSLRedirect"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="WWW"
                        hide-details
                        outlined
                        :items="wWW.records"
                        item-text="value"
                        item-value="id"
                        dense
                        v-model="editData.data.wWWRedirect"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        label="دامین خارجی"
                        hide-details
                        dense
                        v-model="editData.data.externalDomain"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        label="مجوز ارسال سازمانی"
                        hide-details
                        dense
                        v-model="editData.data.licenseToSendMail"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        label="فعال"
                        hide-details
                        dense
                        v-model="editData.data.isActive"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:FormChangeLogo>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-img
                      v-if="oldImage"
                      class="mx-auto"
                      min-width="100"
                      min-height="100"
                      max-width="200"
                      max-height="200"
                      :src="`data:image/png;base64,${oldImage}`"
                    ></v-img>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      v-model="image"
                      color="deep-purple-accent-4"
                      counter
                      label="انتخاب فایل"
                      placeholder="انتخاب فایل"
                      prepend-icon="mdi-paperclip"
                      variant="outlined"
                      :show-size="1000"
                      v-show="true"
                      ref="fileInput"
                      accept="image/*"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
            <template v-slot:FormAccess>
              <v-card-text>
                <v-text-field
                  label="عنوان بانک"
                  outlined
                  dense
                  v-model="editData.data.title"
                ></v-text-field>
              </v-card-text>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      image: null,
      oldImage: null,
      sSLList: [],
      wWW: [],
      addData: {
        url: "domain/create/",
        domainAddress: null,
        subDomain: null,
        sSLRedirect: null,
        wWWRedirect: null,
        companyName: null,
        webmasterFirstname: null,
        webmasterLastName: null,
        externalDomain: true,
        webmasterEmail: null,
        webmasterMobileInfo: null,
        licenseToSendMail: true,
        isActive: true,
        test: this.$refs,
      },
      editData: {
        url: "domain/update/",
        data: {
          domainAddress: null,
          subDomain: null,
          sSLRedirect: null,
          wWWRedirect: null,
          companyName: null,
          webmasterFirstname: null,
          webmasterLastName: null,
          externalDomain: true,
          webmasterEmail: null,
          webmasterMobileInfo: null,
          licenseToSendMail: true,
          isActive: true,
        },
      },
      removeData: { url: "domain/delete/" },
      headers: [
        {
          text: "عنوان شرکت",
          align: "center",
          value: "tenantFullName",
        },
        {
          text: "نام",
          align: "center",
          value: "webmasterFirstname",
        },
        {
          text: "نام خانوادگی",
          align: "center",
          value: "webmasterLastName",
        },
        {
          text: "شماره موبایل",
          align: "center",
          value: "webmasterMobile",
        },
        {
          text: "آدرس ایمیل",
          align: "center",
          value: "webmasterEmail",
        },
        // {
        //   text: "دامین خارجی",
        //   align: "center",
        //   value: "externalDomain",
        // },
        // {
        //   text: "ساب دامین",
        //   align: "center",
        //   value: "subDomain",
        // },
        // {
        //   text: "آدرس دامین",
        //   align: "center",
        //   value: "domainAddress",
        // },
        {
          text: "آدرس کامل وبسایت",
          align: "center",
          value: "domain",
        },
        {
          text: "تاریخ ثبت نام",
          align: "center",
          value: "createDate",
        },
        // {
        //   text: "حجم استفاده شده",
        //   align: "center",
        //   value: "totalHDDUsage",
        // },
        {
          text: "پیام ارسالی",
          align: "center",
          value: "messageSentCount",
        },
        {
          text: "اشخاص",
          align: "center",
          value: "activeUserCount",
        },
        {
          text: "شرکت ها",
          align: "center",
          value: "companyCount",
        },
        {
          text: "کاربران",
          align: "center",
          value: "manCount",
        },
        {
          text: "قرارداد",
          align: "center",
          value: "contract.length",
        },
        {
          text: "مشتریان",
          align: "center",
          value: "customerTrackerCount",
        },
        {
          text: "قرارداد",
          align: "center",
          value: "contractCount",
        },
        // {
        //   text: "استیکر",
        //   align: "center",
        //   value: "stickyCount",
        // },
        // {
        //   text: "پیگیری",
        //   align: "center",
        //   value: "todoListTrackerCount",
        // },
        {
          text: "پروژه ها",
          align: "center",
          value: "projectCount",
        },
        {
          text: "اسناد",
          align: "center",
          value: "documentCount",
        },
        {
          text: "فاکتور ها",
          align: "center",
          value: "invoiceCount",
        },
        {
          text: "محتوا",
          align: "center",
          value: "blogCount",
        },
        // {
        //   text: "پشتیبانی",
        //   align: "center",
        //   value: "ticketCount",
        // },
        {
          text: "فعال",
          align: "center",
          value: "isActive",
        },
        // {
        //   text: "مجوز ارسالی سازمان",
        //   align: "center",
        //   value: "licenseToSendMail",
        // },
        {
          text: "utm",
          align: "center",
          value: "utm",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getDomainList", "getBaseUrl"]),
  },
  created() {
    this.getDomain();
    this.getDataSSL();
    this.getDataWWW();
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "domain/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      this.$store.dispatch("sendList", {
        url: "domain/list/",
        page: item,
        phrase: this.searchTest,
      });

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    getDomain() {
      this.$store.dispatch("sendList", {
        url: "domain/list/",
        page: 1,
        phrase: "",
      });
    },
    getData(item) {
      axios
        .get(`${this.getBaseUrl}/domain/get/`, {
          params: {
            Id: item.tenantId,
            Language: 0,
          },
        })
        .then((response) => {
          this.editData.data = response.data.record;
          if (response.data.record.logo != "")
            this.oldImage = response.data.record.logo;
          this.editData.data.test = this.$refs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataSSL() {
      axios
        .get(`${this.getBaseUrl}/enum/sslredirects/`, {
          params: {
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response);
          this.sSLList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataWWW() {
      axios
        .get(`${this.getBaseUrl}/enum/wwwredirects/`, {
          params: {
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response);
          this.wWW = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onChangeLogo(item) {
      axios
        .put(
          `${this.getBaseUrl}/domain/changelogo/`,
          {
            Language: 0,
            TenantId: item.tenantId,
            logo: this.image,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((data) => {
          if (data.data.status == 1) {
            this.$refs.grid.closeFormChangeLogo();
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 2000,
            });
            this.image = null;
            this.oldImage = null;
          } else {
            this.$store.commit("setMessage", {
              message: data.data.messge,
              color: "red",
            });
          }
        })
        .catch((error) => {
          this.$store.commit("setMessage", {
            message: error.message,
            color: "red",
            timeOut: 2000,
          });
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
