<template>
  <div v-show="showList(task.checkList)" class="task-checklist">
    <div class="progress">
      <v-progress-linear :value="completion" />
    </div>
    <div
      v-for="item in task.checkList"
      :key="item.id"
      class="item"
      @mouseover="showButtons = item.id"
      @mouseleave="showButtons = null"
    >
      <div class="parent">
        <div class="check">
          <div class="pretty p-default">
            <input
              :id="item.id"
              v-model="item.checked"
              type="checkbox"
              @change="toggleCheckItem(item)"
              @click.stop=""
            />
            <div class="state p-primary">
              <label :for="item.id" v-html="linkifyHtml(item.name)" />
            </div>
          </div>
        </div>
        <div v-show="showButtons === item.id" class="right">
          <v-icon
            small
            @click="
              (event) => {
                event.stopPropagation();
                selectedItem = item;
                onConvert();
              }
            "
          >
            mdi-format-list-bulleted
          </v-icon>
          <v-icon
            color="error"
            small
            @click="
              (event) => {
                event.stopPropagation();
                selectedItem = item;
                onDelete();
              }
            "
          >
            mdi-delete
          </v-icon>
        </div>
        <div class="clear" />
      </div>
    </div>
  </div>
</template>

<script>
import TextRenderingMixin from "@/latelier/imports/ui/mixins/TextRenderingMixin.js";
import axios from "axios";
import { mapState, mapGetters } from "vuex";

export default {
  name: "TaskChecklist",
  mixins: [TextRenderingMixin],
  i18n: {
    messages: {
      en: {
        "Convert element to task?": "Convert element to task?",
        "Delete element?": "Delete element?",
        Convert: "Convert",
      },
      fr: {
        "Convert element to task?": "Transformer en tâche ?",
        "Delete element?": "Supprimer l'élément ?",
        Convert: "Convertir",
      },
    },
  },
  props: {
    hideIfEmpty: {
      type: Boolean,
      value: false,
    },
    task: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editNewItem: false,
      selectedItem: {},
      showButtons: null,
      completion: 75,
    };
  },
  watch: {
    task: {
      immediate: true,
      handler(task) {
        this.calculateProgress(task);
      },
    },
    "task.checkList": {
      immediate: true,
      handler(t) {
        this.calculateProgress(this.task);
      },
    },
  },
  computed: {
    ...mapGetters(["getBaseUrl"]),
  },
  methods: {
    showList(checkList) {
      if (this.hideIfEmpty && !this.hasItems(checkList)) {
        return false;
      }
      return true;
    },

    hasItems(checkList) {
      return checkList && checkList.length > 0;
    },

    deleteItem(e, item) {
      if (e) {
        e.stopPropagation();
      }
      Meteor.call("tasks.removeChecklistItem", this.task._id, item._id);
    },
    calculateProgress(task) {
      if (!task.checkList) {
        this.completion = 0;
        return;
      }
      const totalItems = task.checkList.length;
      let completedItems = 0;
      task.checkList.forEach((item) => {
        if (item.checked) {
          completedItems += 1;
        }
      });
      this.completion = 100 * (completedItems / totalItems);
    },

    toggleCheckItem(item) {
      // Meteor.call(
      //   "tasks.toggleCheckItem",
      //   this.task._id,
      //   item._id,
      //   item.checked
      // );
      axios
        .put(`${this.getBaseUrl}/taskcheckitem/toggle/`, {
          Language: 0,
          id: item.id,
          checked: item.checked,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.calculateProgress(this.task);
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
            });
          } else if (data.data.status == 0) {
            this.$store.commit("setMessage", {
              message: data.data.message,
              color: "red",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    onConvert() {
      this.$confirm(this.$t("Convert element to task?"), {
        title: this.$t("Confirm"),
        cancelText: this.$t("Cancel"),
        confirmText: this.$t("Convert"),
      }).then((res) => {
        if (res) {
          // Meteor.call(
          //   "tasks.convertItemToTask",
          //   this.task._id,
          //   this.selectedItem._id
          // );
          axios
            .post(`${this.getBaseUrl}/taskcheckitem/convertitemtotask/`, {
              Language: 0,
              id: this.selectedItem.id,
            })
            .then((data) => {
              if (data.data.status == 1) {
                this.$store.commit("removeTaskcheckItem", {
                  taskItemId: this.task.id,
                  id: this.selectedItem.id,
                });
                this.$store.commit("addTaskItems", data.data.taskItem);

                this.$store.commit("setMessage", {
                  message: "درخواست با موفقیت انجام شد",
                  color: "success",
                });
              } else if (data.data.status == 0) {
                this.$store.commit("setMessage", {
                  message: data.data.message,
                  color: "red",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {});
        }
      });
    },

    onDelete() {
      this.$confirm(this.$t("Delete element?"), {
        title: this.$t("Confirm"),
        cancelText: this.$t("Cancel"),
        confirmText: this.$t("Delete"),
      }).then((res) => {
        if (res) {
          // Meteor.call(
          //   "tasks.removeChecklistItem",
          //   this.task._id,
          //   this.selectedItem._id
          // );

          axios
            .delete(`${this.getBaseUrl}/taskCheckitem/delete/`, {
              data: {
                Id: this.selectedItem.id,
                Language: 0,
              },
            })
            .then((data) => {
              if (data.data.status == 1) {
                this.$store.commit("removeTaskcheckItem", {
                  taskItemId: this.task.id,
                  id: this.selectedItem.id,
                });
                this.$store.commit("setMessage", {
                  message: "درخواست با موفقیت انجام شد",
                  color: "success",
                  timeOut: 2000,
                });
              } else if (data.data.status == 0) {
                this.$store.commit("setMessage", {
                  message: data.data.message,
                  color: "red",
                  timeOut: 2000,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {});
        }
      });
    },
  },
};
</script>

<style scoped>
.task-checklist {
  margin-bottom: 12px;
  position: relative;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.item {
  min-width: 250px;
}

.progress {
  padding-left: 12px;
}

.check {
  float: right;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;
  margin-top: 0;
  font-size: 12px;
  margin-top: 12px;
}

.parent {
  position: relative;
}

.right {
  position: absolute;
  left: 24px;
  top: 12px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.clear {
  clear: both;
}

pre {
  white-space: pre-wrap;
}

.empty-state {
  transition: none;
}

.note {
  margin: 8px;
}

.metadata {
  display: flex;
  flex-direction: row;
}

.metadata .author-line {
  display: inline-block;
  flex: 1;
}
.metadata .action {
  display: inline-block;
}

.metadata .action .v-btn {
  margin-top: -10px;
  padding-top: 0;
}

.center {
  text-align: center;
}

.add-item {
  margin-right: -2px;
}

.add-item label {
  font-size: 13px;
}
.pretty .state label:before {
  background-color: white;
}
.pretty {
  white-space: normal !important;
  margin-left: 48px;
}

.pretty .state label {
  text-indent: 0;
  padding-right: 0.5rem;
  cursor: pointer;
}

.pretty .state label:after,
.pretty .state label:before {
  top: 0;
}

.pretty input {
  width: 20px;
}

.state.p-primary {
  display: inline-block;
}
</style>
