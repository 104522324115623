<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            @ShowHelp="showHelp()"
            :items="getProjectgroup"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="formAdd">
                <v-card-text>
                  <v-text-field
                    label="عنوان گروه های پروژه"
                    outlined
                    dense
                    v-model="addData.projectGroupTitle"
                  ></v-text-field>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-text-field
                    label="عنوان گروه های پروژه"
                    outlined
                    dense
                    v-model="editData.data.projectGroupTitle"
                  ></v-text-field>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
  
<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
export default {
  name: "Banks",
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      addData: {
        url: "projectgroup/create/",
        formName: "formAdd",
        projectGroupTitle: null,
      },
      editData: {
        url: "projectgroup/update/",
        formName: "formEdit",
        data: { projectGroupTitle: null },
      },
      removeData: { url: "projectgroup/delete/" },
      headers: [
        {
          text: "عنوان گروه های پروژه",
          align: "start",
          value: "projectGroupTitle",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getProjectgroup", "getBaseUrl"]),
  },
  created() {
    this.getBanks();
  },
  methods: {
    showHelp(){
      this.$helperDialog.showHelper({
        url:'/help/project/projectCategory/projectCategory.txt',
        video:'/help/project/projectCategory/projectCategory.mp4'})
    },
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "projectgroup/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "projectgroup/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getBanks() {
      this.$store.dispatch("sendList", {
        url: "projectgroup/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      axios
        .get(`${this.getBaseUrl}/projectgroup/get/`, {
          params: {
            Id: item.projectGroupId,
            Language: 0,
          },
        })
        .then((response) => {
          this.editData.data = response.data.record;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
  
<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>