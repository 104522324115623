<template>
  <section class="section__login">
    <v-container fluid>
      <v-row class="my-sm-16 my-md-0">
        <v-col
          cols="12"
          md="4"
          class="mx-auto py-sm-16 my-sm-16 my-md-0 py-lg-16 my-xl-16 my-16"
        >
          <v-card
            class="section__login-body mx-auto rounded-xl elevation-24 py-8 px-1"
            max-width="320"
          >
            <v-row>
              <!-- <v-col cols="12" class="text-center">
                <h3>ورود</h3>
              </v-col> -->
              <v-col cols="12" class="text-center">
                <v-avatar size="100">
                  <img :src="dataCompany.logo" alt="Image" />
                </v-avatar>
              </v-col>
              <v-col cols="12" class="text-center">
                <p>
                  <strong>{{ dataCompany.tenantFullName }}</strong> نرم افزار
                  اتوماسیون اداری
                </p>
              </v-col>
            </v-row>
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="موبایل"
                      outlined
                      hide-details
                      dense
                      v-model="login.username"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-2"
                      label="رمز ورود"
                      value="wqfasds"
                      outlined
                      dense
                      class="input-group--focused"
                      @click:append="show1 = !show1"
                      v-model="login.userPass"
                      v-on:keyup.enter="sendLogin"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-row>
                      <v-col cols="1"></v-col>

                      <v-col cols="2">
                        <v-btn
                          fab
                          dark
                          small
                          class="mt-2"
                          @click="getCaptcha()"
                        >
                          <v-icon dark> mdi-reload </v-icon>
                        </v-btn>
                      </v-col>

                      <v-col cols="9">
                        <img
                          :src="this.captcha.captchaImgUrl"
                          max-width="500"
                          max-height="300"
                          height="60"
                      /></v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      name="input-10-2"
                      label="کد امنیتی"
                      outlined
                      dense
                      class="input-group--focused"
                      v-model="captcha.captchaInputText"
                      v-on:keyup.enter="sendLogin"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="12" class="px-5">
                  <v-btn
                    dark
                    v-on:click="sendLogin"
                    style="background: #1f3c5a"
                    block
                    :disabled="loading"
                  >
                    <span v-if="!loading"> ورود </span>
                    <v-progress-circular
                      v-if="loading"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="text-center">
                  <p
                    v-if="canRegister"
                    v-on:click="goToRegister"
                    class="section__login-btn mt-3"
                  >
                    ثبت نام
                  </p>
                  <p class="section__login-btn">فراموشی رمز عبور</p>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import fa from "vuetify/lib/locale/fa";
export default {
  data() {
    return {
      loading: false,
      show1: false,
      dataCompany: {
        domainId: null,
        logo: "https://businesssoftware.ir/_nuxt/img/logo.4257b27.svg",
        tenantFullName: "",
      },
      logo: null,
      login: {
        username: null,
        userPass: null,
        domainFk: 0,
      },
      captcha: {
        captchaImgUrl: "",
        captchaTextValue: "",
        captchaTokenValue: "",
        captchaInputText: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getBaseUrl", "getFileUrl"]),
    canRegister() {
      return window.location.hostname.startsWith("crm.");
    },
  },
  methods: {
    getCompanyData() {
      axios
        .get(`${this.getBaseUrl}/login/get/`, {
          params: {
            WebRootPath:
              window.location.hostname == "localhost"
                ? "test17.dayavo.com"
                : window.location.hostname,
            Language: 0,
          },
        })
        .then((response) => {
          if (response.data.status == 1) {
            this.dataCompany.tenantFullName =
              response.data.record.tenantFullName;
            if (response.data.record.logo) {
              this.dataCompany.logo =
                "data:image/png;base64," + response.data.record.logo;
            }
          } else if (response.data.status == 0) {
            this.$store.commit("setMessage", {
              message: response.data.message,
              color: "red",
              timeOut: 3000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToRegister() {
      this.$router.push({ name: "Register" });
    },
    sendLogin() {
      this.loading = true;
      let data = {
        Username: this.login.username,
        Password: this.login.userPass,
        WebRootPath:
          window.location.hostname == "localhost"
            ? "test17.dayavo.com"
            : window.location.hostname,
        Language: 0,
        captchaTextValue: this.captcha.captchaTextValue,
        captchaTokenValue: this.captcha.captchaTokenValue,
        captchaInputText: this.captcha.captchaInputText,
      };
      axios
        .post(`${this.getBaseUrl}/Authenticate/Authenticate`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status == 1) {
            this.$store.dispatch("setToken", response.data.token);
            this.$router.push("/").catch(() => {});
            this.$store.commit("setMessage", {
              message: "خوش آمدید",
              color: "success",
              timeOut: 3000,
            });

            this.dataCompany.logo = response.data.logo;
            this.dataCompany.tenantFullName = response.data.tenantFullName;
            localStorage.setItem(
              "tenantFullName",
              this.dataCompany.tenantFullName
            );
            this.dataCompany.logo =
              "data:image/png;base64," + this.dataCompany.logo;

            localStorage.setItem("userId", response.data.userId);
            localStorage.setItem("username", response.data.username);
            localStorage.setItem("pic", response.data.pic);
            localStorage.setItem("mobile", response.data.mobile);
            localStorage.setItem("permissions", response.data.permissions);
            localStorage.setItem(
              "name",
              `${response.data.fName} ${response.data.lName}`
            );
            this.$store.state.CurrentUser.userId = response.data.userId;
            this.$store.state.CurrentUser.username = response.data.username;
            this.$store.state.CurrentUser.mobile = response.data.mobile;
            this.$store.state.CurrentUser.permissions =
              response.data.permissions;

            this.$store.state.CurrentUser.pic = response.data.pic
              ? this.getFileUrl + "media/userpic/" + response.data.pic
              : "https://randomuser.me/api/portraits/men/85.jpg";

            this.$store.state.CurrentUser.name = `${response.data.fName} ${response.data.lName}`;
          } else if (response.data.status == 0) {
            this.$store.commit("setMessage", {
              message: response.data.message,
              color: "red",
              timeOut: 3000,
            });
            this.getCaptcha();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCaptcha() {
      axios
        .get(`${this.getBaseUrl}/login/getCaptcha`)
        .then((response) => {
          this.captcha.captchaImgUrl = response.data.captchaImgUrl;
          this.captcha.captchaTextValue = response.data.captchaTextValue;
          this.captcha.captchaTokenValue = response.data.captchaTokenValue;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getCompanyData();
    this.getCaptcha();
  },
};
</script>

<style lang="scss" scoped>
.section__login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/image/login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .section__login-body {
    background: rgba(225, 225, 225, 0.8);
  }
  .section__login-btn {
    font-size: 0.9rem;
    transition: 1s;
    &:hover {
      cursor: pointer;
      color: red;
    }
  }
}
</style>
