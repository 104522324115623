<template>
  <v-card class="calendar rounded-xl " >
    <div class="purple darken-1 pointer" @click="goToday()">
      <v-card-subtitle class="pb-0 grey--text subtitle-1 text--lighten-1">
        {{ year }}
      </v-card-subtitle>
      <v-card-title class="pt-0 white--text subtitle-1">
        {{ weekDay }}
        {{ day }}
        {{ month }}
      </v-card-title>
    </div>
    <v-divider/>
    <v-card-title class=" justify-space-between pb-0">
      <v-btn icon @click="changeMonth(-1)">
        <v-icon>mdi-arrow-right-drop-circle-outline</v-icon>
      </v-btn>
      <span>{{ currentMonthToFa(currentMonth) }}</span>
      <v-btn icon @click="changeMonth(1)">
        <v-icon>mdi-arrow-left-drop-circle-outline</v-icon>
      </v-btn>
    </v-card-title>

    <div class="calendar-wrap pa-2 pb-5">
      <table id="calendar">
        <thead class="">
        <tr>
          <th class="purple--text pb-2 mb-2">ش</th>
          <th class="purple--text pb-2 mb-2">ی</th>
          <th class="purple--text pb-2 mb-2">د</th>
          <th class="purple--text pb-2 mb-2">س</th>
          <th class="purple--text pb-2 mb-2">چ</th>
          <th class="purple--text pb-2 mb-2">پ</th>
          <th class="purple--text pb-2 mb-2">ج</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
    </div>
  </v-card>
</template>

<script>
import moment from "jalali-moment";

moment.locale("fa");

export default {
  name: "CalendarShamsi",
  data() {
    return {
      holidays: [],
      currentMonth: moment().startOf("jMonth"),
      today: new Intl.DateTimeFormat('fa-IR', {dateStyle: 'full'}).format(new Date()),
      month: new Intl.DateTimeFormat('fa-IR', {month: 'short'}).format(new Date()),
      year: new Intl.DateTimeFormat('fa-IR', {year: 'numeric'}).format(new Date()),
      weekDay: new Intl.DateTimeFormat('fa-IR', {weekday: 'long'}).format(new Date()),
      day: new Intl.DateTimeFormat('fa-IR', {day: 'numeric'}).format(new Date()),
    }
  },
  methods: {
    currentMonthToFa(date) {
      let w = new Intl.DateTimeFormat('fa-IR', {month: 'short'}).format(date)
      let y = new Intl.DateTimeFormat('fa-IR', {year: 'numeric'}).format(date)
      return w + "  " + y
    },
    goToday() {
      this.currentMonth = moment().startOf("jMonth");
      this.initCalendar();

    },
    changeMonth(amount) {
      this.currentMonth.add(amount, "jMonth");
      this.initCalendar();
    },
    initCalendar() {
      const calendarBody = document.getElementById("calendar").tBodies[0];
      calendarBody.innerHTML = "";
      // document.getElementById("currentMonth").innerText = this.currentMonth.format(
      //     "jMMMM jYYYY"
      // );

      let startDate = this.currentMonth.clone();
      let endDate = this.currentMonth.clone().endOf("jMonth");

      while (startDate.day() !== 6) {
        startDate.subtract(1, "days");
      }

      while (endDate.day() !== 5) {
        endDate.add(1, "days");
      }

      for (let d = startDate; d.isBefore(endDate); d.add(1, "days")) {
        if (d.day() === 6) {
          var row = calendarBody.insertRow();
        }

        var cell = row.insertCell();
        function toPersianDigits(input) {
          const persianDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
          return input.toString().replace(/\d/g, (digit) => persianDigits[digit]);
        }
        let perNum = toPersianDigits(d.format("jD"))
        if (this.holidays.includes(d.format("jYYYY-MM-DD"))){
          cell.innerHTML = `<span class="yellow--text caption"> ${perNum} </span>`;
        }
        else if (d.isSame(this.currentMonth, "jMonth")){
          cell.innerHTML = `<span class="caption"> ${perNum} </span>`;
        }else {
          cell.innerHTML = `<span class="grey--text caption"> ${perNum} </span>`;
        }


        // // Checking Holidays
        // if (this.holidays.includes(d.format("jYYYY-MM-DD"))) {
        //   cell.style.backgroundColor = "yellow";
        // } else if (d.isSame(this.currentMonth, "jMonth")) {
        //   cell.style.backgroundColor = "white";
        // } else {
        //   cell.style.backgroundColor = "lightgrey";
        // }

        // Tooday Label
        if (d.isSame(moment(), "day")) {
          cell.className = "tooday";
        }
      }
    }
  },
  async mounted() {
    await this.initCalendar()
  }
}
</script>

<style scoped lang="scss">
  ::v-deep.calendar {
    overflow: hidden;
    direction: rtl;
    min-width: 250px;
    font-family: Vazirmatn, sans-serif;
    margin:  10% 0 ;


    .calendar-wrap {
      width: 100%;
    }
    .calendar-wrap table {
      width: 100%;
      border: none;
      border-collapse: collapse;
    }

    .calendar-wrap table tr th {
      border: 0;
    }
    .calendar-wrap table tr td {
      border: 0;
      text-align: center;
    }

    .tooday > span {
      color: #fff;
      background-color: #8E24AA !important;
      border-radius: 50rem;
      padding: 0.22rem 0.35rem 0.08rem 0.35rem;
    }
  }
</style>