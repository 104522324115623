<template>
  <div >
    <Shortcuts :setColor="setColor"/>
    <CalendarShamsi v-if="false"/>
    <v-card class="mt-3 rounded-lg">

      <v-date-picker
          v-model="today"
          first-day-of-week="6"
          class="custom-date-picker"
          :allowed-dates="getAllowedDates"
          :color="!changebackgroundColor ? 'purple darken-1' : setColor"
          full-width
          readonly
      ></v-date-picker>
    </v-card>
    <v-card
        class="mt-3 text-center rounded-lg scrollable-card"
        :style="{ background: setColor }"
    >
      <v-container>
        <v-row>
          <v-col
              cols="6"
              v-for="(logo, index) in getLinksList.records"
              :key="index"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                  min-height="40"
                  min-width="75"
                  :style="`background: linear-gradient(${
                  index * 90
                }deg,#EEEEEE,white)`"
                  class="text-center rounded-xl card"
                  v-on:click="goToUrl(logo)"
              >
                <v-row>
                  <v-col cols="6" style="padding: 0 !important">
                    <v-icon
                        class="hidden"
                        :class="{ show: hover }"
                        v-on:click.stop="removeItem(logo)"
                        small
                    >mdi-close
                    </v-icon
                    >
                  </v-col>
                  <v-col cols="6" style="padding: 0 !important"
                  >
                    <v-icon
                        class="hidden"
                        :class="{ show: hover }"
                        v-on:click.stop="openEditLink(logo)"
                        small
                    >mdi-pencil
                    </v-icon
                    >
                  </v-col
                  >
                </v-row>
                <v-card-subtitle class="mt-n7">
                  {{ logo.caption }}
                </v-card-subtitle>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="3" sm="2" lg="6">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <router-link :to="{ name: 'Links' }">
                  <v-card
                      v-bind="attrs"
                      v-on="on"
                      min-height="40"
                      min-width="75"
                      :style="`background: linear-gradient(${
                      1 * 90
                    }deg,#EEEEEE,white)`"
                      class="text-center rounded-xl card"
                      v-on:click="goToUrl(logo)"
                  >
                    <v-row>
                      <v-col cols="6" style="padding: 0 !important">
                        <v-icon
                            style="visibility: hidden"
                            v-on:click.stop="removeItem(logo)"
                            small
                        >mdi-close
                        </v-icon
                        >
                      </v-col
                      >
                      <v-col cols="6" style="padding: 0 !important"
                      >
                        <v-icon style="visibility: hidden" small
                        >mdi-pencil
                        </v-icon
                        >
                      </v-col
                      >
                    </v-row>
                    <v-card-subtitle class="mt-n7">
                      <v-icon>mdi-plus</v-icon>
                    </v-card-subtitle>
                  </v-card>
                </router-link>
              </template>
              <span>افزودن</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card flat class="mt-3 px-2">
      <v-btn block v-on:click="changeActiveColor">تغییر رنگ تم</v-btn>

      <v-expand-transition>
        <v-color-picker
            v-if="showChangeColor"
            hide-inputs
            class="ma-2"
            width="700"
            show-swatches
            :swatches="swatches"
            hide-canvas
            mode="hexa"
            v-model="color.hexa"
            light
            swatches-max-height="100px"
        ></v-color-picker>
      </v-expand-transition>
    </v-card>

  </div>
</template>

<script>

import axios from "axios";
import {mapGetters} from "vuex";
import Shortcuts from "./Shortcuts.vue";
import moment from "jalali-moment";
import CalendarShamsi from "@/components/CalendarShamsi";

export default {
  components: {
    CalendarShamsi,
    Shortcuts,

  },
  data() {
    return {
      today: new Date(),
      editLink: false,
      swatches: [
        // ['#835bae' , '#880E4F', '#1565C0'],
        // ['#C62828', '#4E342E', '#757575'],
        // ['#00FF00', '#00AA00', '#005500'],
        ["#7B1FA2", "#1976D2", "#0D47A1"],
        ["#00796B", "#00838F", "#01579B"],
        ["#827717", "#558B2F", "#2E7D32"],
        ["#33691E", "#43A047", "#7CB342"],
        ["#F57C00", "#FFAB00", "#90A4AE"],
        ["#37474F", "#A1887F", "#BDBDBD"],
        ["#616161", "#FFD600", "#FDD835"],
        ["#FFB300", "#CFD8DC", "#00838F"],
        ["#81D4FA", "#B388FF", "#9FA8DA"],
        ["#C51162", "#880E4F", "#E57373"],
      ],
      changebackgroundColor: false,
      events: ["2023-07-02"],
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      showChangeColor: false,
      loading: true,
      color: {alpha: 1, hex: "#835BAE", hexa: "#7B1FA2FF"},
      // '#402957'
      date: {
        Day: null,
        Month: null,
        Year: null,
        Ghamary: null,
        Miladdy: null,
        Event: null,
      },

      logos: [
        {
          Caption: "اسنپ",
          img: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://snapp.ir/&size=16",
          Url: "https://snapp.ir/",
        },
        {
          Caption: "تپسی",
          img: "https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://tapsi.ir/&size=16",
          Url: "https://tapsi.ir/",
        },
        {
          Caption: "دیجیکالا",
          img: "https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://tapsi.ir/&size=16",
          Url: "https://www.digikala.com/",
        },
        {
          Caption: "Gmail",
          img: "https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://tapsi.ir/&size=16",
          Url: "https://www.khabaronline.ir/",
        },
        {
          Caption: "خبر",
          img: "https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://tapsi.ir/&size=16",
          Url: "https://www.khabaronline.ir/",
        },
        {
          Caption: "ایسنا",
          img: "https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://tapsi.ir/&size=16",
          Url: "https://www.isna.ir/",
        },
      ],
    };
  },
  created() {
    let today = new Date()
    const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
    const formattedDate = today.toLocaleDateString('en-CA', options); // 'en-CA' gives YYYY-MM-DD format
    this.today = formattedDate
  },
  mounted() {
    // this.getDate();
    this.getlist();
    this.getLink();
  },
  computed: {
    ...mapGetters(["getMini", "getBaseUrl", "getLinksList"]),
    setColor() {
      return this.$store.state.backgroundApp;
    },
  },
  methods: {
    formatDate(date) {
      moment(date).format('jYYYY/jMM/jDD')
    },
    openEditLink(logo) {
      this.editLink = true;
      console.log(this.editLink);
      // this.$router.push({name:'Home'});
      this.$emit("sendEditLink", {showDialog: this.editLink, logoItem: logo});
    },
    removeItem(item) {
      console.log(item);
      axios
          // `https://www.google.com/s2/favicons?domain=${link}`;
          .post(`${this.getBaseUrl}/link/delete/`, {
            Id: item.LinkId,
            Language: 0,
          })
          .then((response) => {
            console.log(response);
            this.$store.dispatch("sendList", {url: "link/list/", page: 1});
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getLink() {
      // this.$store.dispatch("sendList", { url: "bank/list/" , page: 1});
      this.$store.dispatch("sendList", {url: "link/list/", page: 1});
    },
    changeActiveColor() {
      this.showChangeColor = !this.showChangeColor;
    },
    goToUrl(item) {
      console.log(item);
      window.open(item.url, "_blank");
    },
    getAllowedDates(date) {
      const picker = new Date(date);
      const day = picker.getDay();
      return day !== 5;
    },
    getLogo() {
      axios
          .get("https://api.keybit.ir/time/")
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getDate() {
      axios
          .get("https://api.keybit.ir/time/")
          .then((data) => {
            this.loading = false;
            // console.log(data.data.date.day.number.fa);
            this.date.Day = data.data.date.day.number.fa;
            this.date.Year = data.data.date.year.number.fa;
            this.date.Month = data.data.date.month.name;
            this.date.Ghamary = data.data.date.other.ghamari.usual.fa;
            this.date.Milady = data.data.date.other.gregorian.usual.fa;
            this.date.Event = data.data.date.day.events.global;
          })
          .catch((error) => {
          });
    },
    getlist() {
      //     axios.post('http://crm.businesssoftware.ir/api/user/login/' , {
      //       "Username": "karimi",
      // "UserPass": "123",
      // "Language": 0
      //     })
      //     .then((data)=>{
      //       console.log(data);
      //     })
      //     .catch(()=>{
      //     })
      //       axios({
      //   method: 'post',
      //   url: 'http://crm.businesssoftware.ir/api/menu/list/',
      //   data: {
      //     "Language": 0
      //   }
      // })
      // .then((data)=>{
      //         console.log(data);
      //       })
      //       .catch((error)=>{
      //         console.log(error);
      //       });
    },
  },
  watch: {
    "color.hexa"() {
      // console.log(this.color.hexa , 'this.color');
      this.$store.commit("setColor", this.color);
    },
    setColor() {
      this.changebackgroundColor = true;
    },
  },
};
</script>
<style scoped >

.red--text {
  color: red;
}

.scrollable-card {
  height: 250px; /* ارتفاع ثابت */
  overflow-y: auto; /* امکان اسکرول در جهت عمودی */
}

.v-picker__title__btn,
.v-date-picker-title__date {
  font-size: 1.3rem !important;
  /* text-align: center!important; */
}

.card {
  transition: 0.2s;
  box-shadow: 1px 4px 1px 1px grey !important;
  color: #757575 !important;
}

.card:hover {
  box-shadow: none !important;
  color: black !important;
  background: white;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.card-one {
  transition: 0.5s;
}

.card-one:hover {
  z-index: 1;
  transform: scale(1.1);
}

.v-color-picker__preview:not(.v-color-picker__preview--hide-alpha)
.v-color-picker__hue {
  display: none !important;
}

.hidden {
  visibility: hidden;
}

.show {
  visibility: visible;
}
</style>