<template>
  <v-col cols="12">
    <!-- <v-card class="pa-3"> -->
    <v-row>
      <v-col cols="12" lg="3" xl="3">
        <v-card
          class="rounded-lg"
          :style="iconNote ? 'margin-top:1rem' : ''"
          style="background: #f2f2fa"
          min-height="400"
        >
          <v-row>
            <v-col cols="2" v-if="iconNote && $vuetify.breakpoint.lg">
              <div
                v-for="(n, index) in 23"
                :key="index"
                class="wire pr-6 mt-2 rounded-lg"
              ></div>
            </v-col>
            <v-col :cols="iconNote && $vuetify.breakpoint.lg ? 10 : 12">
              <v-expansion-panels flat v-model="panel">
                <v-expansion-panel
                  style="background: transparent"
                  v-on:click="iconNote = !iconNote"
                >
                  <v-expansion-panel-header>
                    کارهای شخصی
                  </v-expansion-panel-header>

                  <v-card
                    v-if="iconNote"
                    min-height="200"
                    class="text-center mt-16"
                    flat
                    color="transparent"
                  >
                    <v-icon
                      v-if="iconNote"
                      style="transform: scale(4)"
                      x-large
                      class="mt-16"
                      color="deep-purple lighten-4"
                      >mdi-notebook-edit-outline</v-icon
                    >
                  </v-card>
                  <v-expansion-panel-content v-else>
                    <v-card flat>
                      <v-card flat v-for="todo in todos2" :key="todo.id">
                        <v-list dense style="background: #f2f2fa">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ todo.title }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox
                                color="error"
                                v-on:change="removeTodo2(todo)"
                                v-model="todo.checkbox"
                              ></v-checkbox>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" lg="9" xl="9">
        <v-card min-height="400" color="#E0E7F1" class="rounded-lg">
          <v-card flat color="#E0E7F1" class="pa-2">
            <v-card-title>
              <h4 class="font-weight-bold basil--text">لیست کارهای روزانه</h4>
            </v-card-title>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="basil"
              active-class="purple lighten-4"
              grow
              height="35"
            >
              <v-tab v-for="item in itemsTabs" :key="item" class="rounded-t-lg">
                {{ item }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="mt-3">
              <v-tab-item v-for="item in itemsTabs" :key="item">
                <v-card color="#E0E7F1" flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-row>
                          <v-col
                            cols="12"
                            class="d-flex pa-0 mt-n5"
                            :class="{ remove: todo.checkbox }"
                            v-for="todo in todos"
                            :key="todo.id"
                          >
                            <span>
                              <v-checkbox
                                color="error"
                                v-on:change="removeTodo(todo)"
                                v-model="todo.checkbox"
                              ></v-checkbox>
                            </span>
                            <span
                              class="text-todo pt-6"
                              v-on:click="showDescription(todo)"
                            >
                              {{ todo.title }}
                            </span>
                            <span class="pt-6 pr-3">
                              <v-icon> mdi-book-open </v-icon>
                            </span>
                          </v-col>
                          <v-col cols="12" class="pa-0">
                            <v-form>
                              <v-row>
                                <v-col cols="9">
                                  <v-text-field
                                    label="افزودن کار جدید"
                                    placeholder="افزودن کار جدید"
                                    outlined
                                    hide-details
                                    dense
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                  <v-btn>افزودن</v-btn>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" lg="6" class="text-center">
                        {{ description }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <!-- </v-card> -->
  </v-col>
</template>

<script>
export default {
  name: "TaskList",
  data() {
    return {
      iconNote: false,
      description: null,
      panel: 0,
      tab: null,
      todos2: [
        {
          id: 1,
          title: "تایید مرخصی ها",
          checkbox: false,
          des: "تایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی ها تایید مرخصی ها",
        },
        {
          id: 2,
          title: "تایید نامه ها",
          checkbox: false,
          des: "تایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه ها تایید نامه ها",
        },
        {
          id: 3,
          title: "ارسال نامه ها",
          checkbox: false,
          des: "ارسال نامه ها",
        },
        {
          id: 4,
          title: "امضا کارتابل امضا کارتابل امضا کارتابل",
          checkbox: false,
          des: "امضا کارتابل امضا کارتابل امضا کارتابل",
        },
        {
          id: 5,
          title: "تایید مرخصی ها",
          checkbox: false,
          des: "تایید مرخصی ها",
        },
      ],
      itemsTabs: ["الویت یک", "الویت دو", "الویت سه", "الویت چهار"],
      todos: [
        {
          id: 1,
          title: "تایید مرخصی ها",
          checkbox: false,
          des: "تایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی هاتایید مرخصی ها تایید مرخصی ها",
        },
        {
          id: 2,
          title: "تایید نامه ها",
          checkbox: false,
          des: "تایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه هاتایید نامه ها تایید نامه ها",
        },
        {
          id: 3,
          title: "ارسال نامه ها",
          checkbox: false,
          des: "ارسال نامه ها",
        },
        {
          id: 4,
          title: "امضا کارتابل امضا کارتابل امضا کارتابل",
          checkbox: false,
          des: "امضا کارتابل امضا کارتابل امضا کارتابل",
        },
        {
          id: 5,
          title: "تایید مرخصی ها",
          checkbox: false,
          des: "تایید مرخصی ها",
        },
      ],
    };
  },
  methods: {
    removeTodo(todo) {
      setTimeout(() => {
        this.todos = this.todos.filter((element) => {
          return element.id != todo.id;
        });
        if (this.description != null) {
          // console.log(this.description);
          this.description = "";
        }
      }, 1000);
    },
    removeTodo2(todo) {
      setTimeout(() => {
        this.todos2 = this.todos2.filter((element) => {
          return element.id != todo.id;
        });
      }, 500);
    },
    showDescription(todo) {
      this.description = todo.des;
    },
  },
};
</script>

<style lang="scss" scoped>
.remove {
  animation-name: removeTodo;
  animation-duration: 2s;
}
.text-todo {
  transition: 1s;
  &:hover {
    color: red;
    cursor: pointer;
  }
}
@keyframes removeTodo {
  0% {
    text-decoration: line-through;
  }
  50% {
    transform: translate(200px);
  }
}
</style>