<template>
  <div style="width: 100%" >
    <v-list-item v-if="menuItem.children.length === 0">
      <v-list-item-icon v-if="index === 0" class="ml-1">
        <v-icon class="custom-link">mdi-home</v-icon>
      </v-list-item-icon>
      <v-list-item-title class="font-weight-bold text-caption pointer"
                         @click="changeRoute(menuItem)"
      >{{ menuItem.title }}
      </v-list-item-title>
    </v-list-item>
    <v-list-group v-model="showChild" v-if="menuItem.children.length > 0">
      <template v-slot:appendIcon>
        <v-icon small> mdi-chevron-down</v-icon>
      </template>
      <template v-slot:activator>
        <v-list-item-icon v-if="icon" class="ml-1">
          <v-icon small class="custom-link">{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="font-weight-bold text-caption pointer"
                           @click="changeRoute(menuItem)"
        >{{ menuItem.title }}
        </v-list-item-title>
      </template>
      <v-list class="mr-1 pr-8 py-0 MenuNavigationItem" shaped
              v-for="(child) in menuItem.children" :key="child.title"
      >
        <MenuNavigationItem :menu-item="child"/>
      </v-list>
    </v-list-group>
  </div>
</template>

<script>
export default {
  name: "MenuNavigationItem",
  props: {
    menuItem: {
      type: Object,
      default: {}
    },
    index: {
      type: Number,
    },
    icon: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      root: [
        {routeName: localStorage.getItem("tenantFullName"), route: "Home"},
        {
          routeName: "کارتابل نامه های من",
          route: "MyMessage",
          icon: "mdi-language-html5",
        },
        {routeName: "نامه های داخلی", route: "DomesticLetters"},
        {routeName: "نامه های وارده", route: "IncomingLetters"},
        {routeName: "نامه های صادره", route: "LettersIssued"},
        {routeName: "فرم ها", route: "RequestForm"},
        // { routeName: "نامه های ارجاع شده", route: "Home" },
        {routeName: "کلیه نامه ها", route: "AllMessage"},
        {routeName: "پیش نویس نامه ها", route: "DraftMessage"},
        {routeName: "مدیریت امور", route: "Tasks"},
        {routeName: "لیست مشتریان", route: "CustomerList"},
        {routeName: "دسته بندی مشتریان", route: "CustomerCategory"},
        {routeName: "لیست قراردادها", route: "ContractList"},
        {routeName: "دسته بندی قراردادها", route: "ContractCategory"},
        {routeName: "لیست پروژه ها", route: "ProjectList"},
        {routeName: "دسته بندی پروژه ها", route: "ProjectCategory"},
        {routeName: "لیست اسناد", route: "AccountingList"},
        {routeName: "لیست فاکتورها", route: "AccountingCategory"},
        {routeName: "لیست محتواها", route: "ContentList"},
        {routeName: "دسته بندی محتواها", route: "ContentCategory"},
        {routeName: "لیست پیام ها", route: "TicketList"},
        {routeName: "پستهای سازمانی", route: "OrganizationalPosition"},
        {routeName: "چارت سازمانی", route: "OrganizationalUnit"},
        {routeName: "شرکتها", route: "Company"},
        {routeName: "اشخاص", route: "Person"},
        {routeName: "پلن ها", route: "Plans"},
        {routeName: "مدیریت دامین", route: "Domains"},
        {routeName: "کاربران آنلاین", route: "OnlineUser"},
        {routeName: "مدیریت کاربران", route: "MembersUser"},
        {routeName: "گروه های کاربری", route: "UserGroups"},
        {routeName: "اعضای گروه های کاربری", route: "MembersUser"},
        {routeName: "دسترسی گروه کاربری", route: "UserAccess"},
        // { routeName: "دسترسی اختصاصی", route: "ExclusiveAccess" },
        {routeName: "شماره گذاری اسناد", route: "Andikator"},
        {routeName: "پوشه بندی آرشیو اداری", route: "Archive"},
        {routeName: "پوشه بندی شخصی", route: "Favorite"},
        {routeName: "طراحی قالب نامه", route: "Home"},
        {routeName: "مدیریت قالب نامه", route: "TemplateManager"},
        {routeName: "بانکها", route: "Banks"},
        {routeName: "سرفصلها", route: "Headlines"},
        {routeName: "حسابهای بانکی", route: "BankAccounts"},
        {routeName: "صندوق ها", route: "Funds"},
      ],
      showChild: false,
    }
  },
  methods: {
    changeRoute(value) {
      let result = this.root.find((item) => {
        return item.routeName === value.title;
      });
      if (result) {
        if (this.$router.currentRoute.name === result.route) {
          return;
        }
        this.$router.push({
          name: result.route,
          params: {title: value.title},
        })

      } else if (value.type === "ArchiveMessage" || value.type === "FavoriteMessage") {
        this.$router.push({
          name: value.type,
          params: {title: value.title, typeNumber: value.id},
        })
      }
    },
  }
}
</script>

<style scoped >
.MenuNavigationItem{
  border-bottom: 1px solid #5d3f7c47;
}
.MenuNavigationItem:last-child{
  border: none;
}
</style>