<template>
  <v-card>
    <v-card-title>تنظیمات پروفایل</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field
            outlined
            hide-details
            dense
            label="شماره موبایل "
            placeholder="09xxxxxxxxx"
            v-model="item.userMobile"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-btn compact color="success" v-on:click="changeUserMobile()">
            <v-icon small>mdi-check</v-icon>
            تغییر شماره
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            outlined
            hide-details
            dense
            label="کد تایید "
            placeholder="xxxxx"
            v-model="item.mobileActivationCode"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn compact color="warning" v-on:click="ApproveUserSendCode()">
            <v-icon small>mdi-email-arrow-right</v-icon>
            دریافت کد جدید
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn compact color="success" v-on:click="ApproveUser()">
            <v-icon small>mdi-content-save</v-icon>
            تایید
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn v-on:click="$emit('close')">لغو</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "UserApprove",
  components: {},
  data() {
    return {
      dialog: true,
      item: {
        userMobile: this.$store.state.CurrentUser.mobile,
        mobileActivationCode: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getBaseUrl"]),
  },
  created() {},
  methods: {
    changeUserMobile() {
      axios
        .put(`${this.getBaseUrl}/user/changeusermobile/`, {
          Language: 0,
          Mobile: this.item.userMobile,
        })
        .then((data) => {
          this.$store.commit("setMessage", {
            message: "درخواست با موفقیت انجام شد",
            color: "success",
            timeOut: 2000,
          });
          localStorage.setItem("mobile", this.item.userMobile);
          this.$store.state.CurrentUser.mobile = this.item.userMobile;
          this.$emit("close");
        })
        .catch((error) => {
          this.$store.commit("setMessage", {
            message: error.message,
            color: "red",
            timeOut: 2000,
          });
          console.log(error);
        });
    },
    ApproveUserSendCode() {
      axios
        .post(`${this.getBaseUrl}/user/approveusersendcode/`, {
          Language: 0,
        })
        .then((data) => {
          this.$store.commit("setMessage", {
            message: "درخواست با موفقیت انجام شد",
            color: "success",
            timeOut: 2000,
          });
        })
        .catch((error) => {
          this.$store.commit("setMessage", {
            message: error.message,
            color: "red",
            timeOut: 2000,
          });
          console.log(error);
        });
    },
    ApproveUser() {
      axios
        .post(`${this.getBaseUrl}/user/approveuser/`, {
          Language: 0,
          code: this.item.mobileActivationCode,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 2000,
            });
            this.$emit("close");
          } else {
            this.$store.commit("setMessage", {
              message: data.data.messge,
              color: "red",
            });
          }
        })
        .catch((error) => {
          this.$store.commit("setMessage", {
            message: error.message,
            color: "red",
            timeOut: 2000,
          });
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
