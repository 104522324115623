<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
              :headers="headers"
              :items="getorgunitList"
              v-on:getItem="getData"
              v-on:getList="getPage"
              v-on:getListBySearch="getSearch"
              @ShowHelp="showHelp()"
              :insertItemData="addData"
              :editItemData="editData"
              :removeItemData="removeData"
              :hasAttachment="false"
              :hasPayment="false"
              :hasSupplement="false"
              :hasAction="false"
              :hasContacts="false"
              add="true"
              edit="true"
              remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="formAdd">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          :rules="requiredRule"
                          label="واحد سازمانی"
                          outlined
                          dense
                          hide-details
                          v-model="addData.orgUnitTitle"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          label="زیرمجموعه"
                          outlined
                          :items="getorgunitList.records"
                          item-text="orgUnitTitle"
                          item-value="orgUnitId"
                          dense
                          hide-details
                          v-model="addData.parentOrgUnitFk"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                          label="فعال"
                          v-model="addData.isActive"
                          dense
                          hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          :rules="requiredRule"
                          label="پست سازمانی"
                          outlined
                          dense
                          hide-details
                          v-model="editData.data.orgUnitTitle"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                          @update:search-input="searchOnList"
                          label="واحد سازمانی"
                          outlined
                          :items="getorgunitList.records"
                          item-text="orgUnitTitle"
                          item-value="orgUnitId"
                          dense
                          hide-details
                          v-model="editData.data.parentOrgUnitFk"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                          label="فعال"
                          v-model="editData.data.isActive"
                          dense
                          hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import MenuLeft from "../../components/MenuLeft.vue";
import AccountingSystem from "../../components/AccountingSystem.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import {myMixin} from "../../../mixins.js";

export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      search:null,
      searchTest: "",
      addData: {
        url: "orgunit/create/",
        formName: "formAdd",
        parentOrgUnitFk: null,
        orgUnitTitle: null,
        isActive: false,
      },
      editData: {
        url: "orgunit/update/",
        formName: "formEdit",
        data: {
          parentOrgUnitFk: null,
          orgUnitTitle: null,
          isActive: false,
        },
      },
      removeData: {url: "orgunit/delete/"},
      headers: [
        {
          text: "عنوان واحد سازمانی",
          align: "center",
          value: "orgUnitTitle",
        },
        {
          text: "زیرمجموعه",
          align: "center",
          value: "parentOrgUnitFk",
        },
        {
          text: "فعال",
          align: "center",
          value: "isActive",
        },
        {text: "", value: "actions", align: "left", sortable: false},
      ],
    };
  },
  computed: {
    ...mapGetters(["getorgpostList", "getorgunitList", "getBaseUrl"]),
  },
  created() {
    this.getorgunit();
  },
  methods: {
    showHelp(){
      this.$helperDialog.showHelper({
        url:'/help/settingApp/basicInformation/organizationalUnit/organizationalUnit.txt',
        video:'/help/settingApp/basicInformation/organizationalUnit/organizationalUnit.mp4'})
    },
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "orgunit/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "orgunit/list/",
        page: item,
        phrase: this.searchTest,
      });
    },

    getorgunit() {
      this.$store.dispatch("sendList", {
        url: "orgunit/list/",
        page: 1,
        phrase: "",
        count: 100,
      });
    },
    searchOnList(val) {
      this.getSearch(val)
    },
    getData(item) {
      axios
          .get(`${this.getBaseUrl}/orgunit/get/`, {
            params: {
              Id: item.orgUnitId,
              Language: 0,
            },
          })
          .then((response) => {
            this.editData.data = response.data.record;
            this.editData.data.test = this.$refs;
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
