/* eslint max-len: 285 */
<template>
  <div
    class="task"
    tabindex="0"
    @drop="onDrop"
    @dragover="onDragOver"
    @keyup.enter="selectTask"
    @click="selectTask"
    @mouseenter="showEditButton = true"
    @mouseleave="showEditButton = false"
  >
    <v-lazy
      :options="{
        threshold: 0.2,
      }"
      min-height="54"
      transition="fade-transition"
    >
      <div ref="card" class="card" :class="{ selected, completed }">
        <!-- <task-labels-in-card class="labels" :task="task" /> -->
        <div class="task-title">
          <v-icon
            v-show="showEditButton && !editName"
            icon
            text
            class="edit-button"
            small
            color="primary"
            @click.stop="startUpdateName"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-show="showEditButton && !editName"
            icon
            text
            class="delete-button"
            small
            color="error"
            @click.stop="deleteTask"
          >
            mdi-delete
          </v-icon>

          <div class="title-wrapper">
            <div v-if="!editName" class="checkbox">
              <div class="pretty p-svg p-curve">
                <input
                  v-show="!editName"
                  v-model="completed"
                  type="checkbox"
                  @click="(e) => e.stopPropagation()"
                />
                <div class="state p-primary">
                  <svg class="svg svg-icon" viewBox="0 0 20 20">
                    <!-- eslint-disable -->
                    <path
                      d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                      style="stroke: white; fill: white"
                    />
                    <!-- eslint-enable -->
                  </svg>
                  <label />
                </div>
              </div>
            </div>
            <div
              v-show="!editName"
              :class="getClassForName(task)"
              v-html="linkifyHtml(task.name)"
            />
            <v-icon
              v-show="hasAttachments(task) && !editName"
              :class="getClassForAttachment(task)"
              small
              color="blue darken-1"
            >
              mdi-paperclip
            </v-icon>
            <v-icon
              v-show="hasNotes(task) && !editName"
              class="has-notes"
              small
              color="blue darken-1"
            >
              mdi-message-text
            </v-icon>

            <span v-show="editName" class="edit">
              <v-textarea
                ref="name"
                v-model="task.name"
                class="edit-name"
                background-color="white"
                autofocus
                outlined
                auto-grow
                solo
                @keyup.esc="cancelUpdateName"
                @keydown.shift.enter="updateName"
              />
              <v-btn icon text @click.native="updateName">
                <v-icon color="green">mdi-check-circle</v-icon>
              </v-btn>

              <v-btn icon text @click.native="cancelUpdateName">
                <v-icon color="red">mdi-close-circle</v-icon>
              </v-btn>
            </span>
          </div>
        </div>
        <v-divider v-if="hasChecklist(task)" />
        <task-checklist :task="task" :hide-if-empty="true" class="checklist" />

        <div v-if="task.completedOnUtc" class="completed-date">
          {{ $t("Completed on") }}
          {{ formatDateTime(task.completedOnUtc) }}
        </div>

        <v-divider v-if="hasFooterData(task)" />
        <div v-if="hasFooterData(task)" class="footer">
          <div class="footer-left">
            <template v-if="task.dueDateOnUtc">
              <v-icon small :class="{ late: isLate }">
                {{ isLate ? "mdi-clock-alert-outline" : "mdi-alarm-check" }}
              </v-icon>
              {{ formatDateTime(task.dueDateOnUtc) }}
            </template>
            <template v-if="isProjectEstimationFeatureEnabled()">
              <template v-if="task.estimationSize">
                <v-icon small> mdi-timer </v-icon>
                {{ task.estimationSize }}
              </template>
              <template v-if="task.estimationSpent">
                <v-icon small> mdi-timelapse </v-icon>
                {{ task.estimationSpent }}
              </template>
            </template>
          </div>
          <!-- <div class="avatar">
            <author-avatar
              v-show="task.assignedTo"
              :user-id="task.assignedTo"
              xsmall
            />
          </div> -->
        </div>
      </div>
    </v-lazy>
  </div>
</template>

<script>
// import { Meteor } from "meteor/meteor";
// import { Projects } from "/imports/api/projects/projects.js";
// import { Attachments } from "/imports/api/attachments/attachments";
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import usersMixin from "@/latelier/imports/ui/mixins/UsersMixin.js";
import TextRenderingMixin from "@/latelier/imports/ui/mixins/TextRenderingMixin.js";
import DatesMixin from "@/latelier/imports/ui/mixins/DatesMixin.js";
import TaskLabelsInCard from "./TaskLabelsInCard.vue";
import TaskChecklist from "./TaskChecklist.vue";
import AuthorAvatar from "./AuthorAvatar.vue";

export default {
  mixins: [usersMixin, TextRenderingMixin, DatesMixin],
  components: { TaskLabelsInCard, TaskChecklist, AuthorAvatar },
  props: {
    task: {
      default: () => {},
    },
  },
  data() {
    return {
      selected: false,
      editName: false,
      savedName: "",
      showEditButton: false,
      completed: false,
      showConfirmDeleteDialog: false,
    };
  },
  computed: {
    ...mapState(["selectedTask"]),
    ...mapGetters(["getBaseUrl"]),
    ...mapState("project", ["currentProjectId"]),
    ...mapGetters("project", ["hasProjectFeature"]),
    isLate: {
      get() {
        return (
          this.task &&
          this.task.dueDateOnUtc &&
          this.task.dueDateOnUtc <= new Date() &&
          !this.task.completed
        );
      },
    },
  },
  watch: {
    "task.completed": {
      immediate: true,
      handler(completed) {
        if (this.completed !== completed) {
          this.completed = completed;
        }
      },
    },
    completed(completed) {
      //Meteor.call("tasks.complete", this.task._id, completed);

      axios
        .put(`${this.getBaseUrl}/taskitem/completed/`, {
          Id: this.task.id,
          Completed: completed,
          Language: 0,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.commit("replaceTaskItem", data.data.record);
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
            });
          } else if (data.data.status == 0) {
            this.$store.commit("setMessage", {
              message: data.data.message,
              color: "red",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    selectedTask(selectedTask) {
      if (selectedTask && selectedTask.id === this.task.id) {
        if (!this.isVisible()) {
          this.$el.scrollIntoView({
            behavior: "smooth",
          });
        }
        this.selected = true;
      } else {
        this.selected = false;
      }
    },
  },
  mounted() {
    this.$events.listen("task-edit-name", (task) => {
      if (task._id !== this.task._id) {
        return;
      }
      this.startUpdateName();
    });
    this.$events.listen("task-cancel-edit-name", (task) => {
      if (task._id !== this.task._id) {
        this.editName = false;
      }
    });
  },
  beforeDestroy() {
    this.$events.off("task-edit-name");
    this.$events.off("task-cancel-edit-name");
  },
  methods: {
    startUpdateName(e) {
      if (e) {
        e.stopPropagation();
      }
      this.$events.fire("task-cancel-edit-name", this.task);
      this.$events.fire("task-disable-drag");
      this.savedName = this.task.name;
      this.editName = true;
      this.$nextTick(() => this.$refs.name.focus());
    },

    updateName(e) {
      if (e) {
        e.stopPropagation();
      }
      this.$events.fire("task-enable-drag");
      this.editName = false;
      if (this.task.name.length === 0) {
        this.task.name = this.savedName;
      }
      // Meteor.call(
      //   "tasks.updateName",
      //   this.task._id,
      //   this.task.name,
      //   (error) => {
      //     if (error) {
      //       this.$notifyError(error);
      //       this.task.name = this.savedName;
      //     }
      //   }
      // );
      axios
        .put(`${this.getBaseUrl}/taskitem/updateName/`, {
          Id: this.task.id,
          name: this.task.name,
          Language: 0,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
            });
          } else if (data.data.status == 0) {
            this.$store.commit("setMessage", {
              message: data.data.message,
              color: "red",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    cancelUpdateName(e) {
      if (e) {
        e.stopPropagation();
      }
      this.$events.fire("task-enable-drag");
      this.editName = false;
      this.task.name = this.savedName;
    },

    selectTask(e) {
      e.stopPropagation();
      if (this.editName) {
        return;
      }
      this.$router
        .push({
          name: "project-task",
          params: {
            projectId: 0,
            taskId: this.task.id,
          },
        })
        .catch(() => {});
    },
    getClassForName() {
      const classes = ["name"];

      if (this.completed) {
        classes.push("completed");
      }
      return classes.join(" ");
    },

    hasChecklist(task) {
      if (task.checkList && task.checkList.length > 0) {
        return true;
      }
      return false;
    },

    hasNotes(task) {
      if (task.notes && task.notes.length > 0) {
        return true;
      }
      return false;
    },

    hasAttachments(task) {
      return false;
      // if (Meteor.settings.public.disableAttachments) {
      //   return false;
      // }
      // return Attachments.find({ "meta.taskId": task._id }).count() > 0;
    },

    hasFooterData(task) {
      return (
        task.dueDateOnUtc || task.assignedTo || this.hasEstimationData(task)
      );
    },

    hasEstimationData(task) {
      // if (!this.isProjectEstimationFeatureEnabled()) {
      //   return false;
      // }
      return task.estimationSize || task.estimationSpent;
    },

    isProjectEstimationFeatureEnabled() {
      return true;
      return this.hasProjectFeature("estimation");
    },

    getColor(projectId) {
      // const project = Projects.findOne({ _id: projectId });
      // if (project && project.color) {
      //   return `background-color: ${project.color}`;
      // }
      return "background-color: #2D6293";
    },

    deleteTask() {
      this.$events.fire("delete-task", this.task);
    },

    getClassForAttachment(task) {
      return this.hasNotes(task)
        ? "has-attachments-shifted"
        : "has-attachments";
    },

    onDrop(event) {
      event.preventDefault();
      return;
      // if (Meteor.settings.public.disableAttachments) {
      //   return;
      // }

      // const { task } = this;
      // const files = [];
      // if (event.dataTransfer.items) {
      //   for (let i = 0; i < event.dataTransfer.items.length; i++) {
      //     if (event.dataTransfer.items[i].kind === "file") {
      //       const file = event.dataTransfer.items[i].getAsFile();
      //       files.push(file);
      //     }
      //   }
      // } else {
      //   for (let i = 0; i < event.dataTransfer.files.length; i++) {
      //     files.push(event.dataTransfer.files[i]);
      //   }
      // }
      // if (files.length === 0) {
      //   return;
      // }
      // event.stopPropagation();

      // const transport = Meteor.settings.public.uploadTransport || "ddp";
      // files.forEach((file) => {
      //   const upload = Attachments.insert(
      //     {
      //       file: file,
      //       chunkSize: "dynamic",
      //       transport: transport,
      //       meta: {
      //         projectId: task.projectId,
      //         taskId: task._id,
      //         createdBy: Meteor.userId(),
      //       },
      //     },
      //     false
      //   );
      //   upload.on("start", function () {});
      //   upload.on("end", function (error) {
      //     if (error) {
      //       this.$notifyError(error);
      //     } else {
      //       Meteor.call("tasks.addAttachment", task._id);
      //     }
      //   });
      //   upload.start();
      // });
    },

    onDragOver(e) {
      e.preventDefault();
    },

    isVisible() {
      const bounding = this.$el.getBoundingClientRect();
      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.task:focus {
  outline: none;
}

.task:focus .card {
  background-color: #c5cae9;
}
.card {
  background-color: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  transition: box-shadow 0.5s ease, opacity 0.5s ease,
    background-color 0.5s ease;
  position: relative;
}

.card.completed {
  opacity: 0.8;
}

.card:hover {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.footer {
  border-radius: 0px 0px 4px 4px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer .v-icon {
  margin-right: 8px;
}

.footer-left {
  margin-right: 4px;
  margin-top: 8px;
  color: #666;
  font-size: 11px;
}

.completed-date {
  margin-right: 12px;
  padding-bottom: 12px;
  color: #666;
  font-size: 11px;
}

.dragup {
  background: linear-gradient(0deg, #fff 50%, #eee 50%);
}

.dragdown {
  background: linear-gradient(0deg, #eee 50%, #fff 50%);
}

.selected {
  background-color: #c5cae9;
}
.pretty .state label:before {
  background-color: white;
}

.task-title {
  position: relative;
  font-size: 20px !important;
  font-weight: 500;
  line-height: 1 !important;
  letter-spacing: 0.02em !important;
}

.title-wrapper {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.checkbox {
  font-size: 13px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 2px;
}

.name {
  font-size: 12px;
  padding-right: 22px;
  line-height: 1.3;
  flex-grow: 1;
  overflow: hidden;
}

.task {
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 12px;
}

.edit {
  width: 100%;
}

.edit .v-btn {
  min-width: 24px;
  width: 24px;
  height: 20px;
  margin: 0;
  margin-top: 4px;
}

.edit-name {
  font-weight: normal;
}

.checklist {
  margin-right: 12px;
  padding-bottom: 12px;
}

.task-checklist {
  font-size: 12px;
  padding-right: 0px;
  padding-top: 8px;
}

.metadata {
  padding-top: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 12px;
}

.card-content {
  padding-right: 12px;
  padding-top: 8px;
  margin-bottom: 0;
  padding-bottom: 8px;
}

.avatar {
  font-size: 11px;
  margin-top: 5px;
  margin-left: 12px;
}
.avatar .v-avatar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.edit-button {
  overflow: hidden;
  position: absolute !important;
  left: 8px;
  top: 4px;
}

.delete-button {
  overflow: hidden;
  position: absolute !important;
  left: 8px;
  top: 40px;
}

.has-notes {
  position: absolute !important;
  left: 8px;
  top: 24px;
}

.has-attachments-shifted {
  position: absolute !important;
  left: 24px;
  top: 24px;
}

.has-attachments {
  position: absolute !important;
  left: 8px;
  top: 24px;
}

.very-small {
  width: 30px;
  height: 30px;
}

.late {
  color: red !important;
}
</style>
