<template>
  <v-card color="basil" flat>
    <v-form ref="formAdd">
      <v-card-text>
        <v-card tile>
          <v-container>
            <v-row>
              <v-col cols="12" lg="6">
                <v-select
                  label="نوع درخواست"
                  hide-details
                  outlined
                  :items="formTypes"
                  item-text="title"
                  item-value="value"
                  dense
                  v-model="data.formType"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="this.data.formType != 0">
              <v-col cols="12" lg="6">
                <date-picker
                  v-model="data.requestDatetime"
                  format="YYYY-MM-DDTHH:mm:ss"
                  display-format="jYYYY/jMM/jDD"
                  label="تاریخ درخواست"
                  :clearable="true"
                  :autoSubmit="true"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <date-picker
                  v-model="data.startDatetime"
                  format="YYYY-MM-DDTHH:mm:ss"
                  :display-format="displayStartTimeFormat"
                  label="تاریخ شروع"
                  :clearable="true"
                  :autoSubmit="true"
                  :type="needStartTime"
                />
              </v-col>
              <v-col cols="12" lg="6" v-if="showEndDatetime">
                <date-picker
                  v-model="data.endDatetime"
                  format="YYYY-MM-DDTHH:mm:ss"
                  :display-format="displayEndTimeFormat"
                  label="تاریخ پایان"
                  :clearable="true"
                  :autoSubmit="true"
                  :type="needEndTime"
                />
              </v-col>
              <v-col cols="12" lg="6" v-if="showCount">
                <v-text-field
                  v-model="data.count"
                  type="number"
                  outlined
                  dense
                  label="تعداد"
                  :rules="requiredRule"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6" v-if="showPrice">
                <v-text-field
                  v-model="data.price"
                  type="number"
                  outlined
                  dense
                  label="مبلغ"
                  :rules="requiredRule"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="showadditionalDescription">
                <v-textarea
                  solo
                  v-model="data.additionalData.additionalDescription"
                  :label="additionalDescriptionLable"
                ></v-textarea>
              </v-col>
              <v-col cols="12" lg="6" v-if="showAdditionalChoiceType">
                <v-select
                  :rules="nameRules"
                  label="نوع"
                  hide-details
                  outlined
                  :items="additionalChoiceType"
                  item-text="title"
                  item-value="value"
                  dense
                  v-model="data.additionalData.additionalChoiceType"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="data.description"
                  solo
                  label="توضیحات"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { myMixin } from "../../../mixins.js";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import VCardTitleClose from "../../components/VCardTitleClose";
import moment from "moment";

export default {
  mixins: [myMixin],
  name: "EditForm",
  components: {
    datePicker: VuePersianDatetimePicker,
    VCardTitleClose,
  },
  props: ["item"],
  data() {
    return {
      data: this.prepareData(),
      formTypes: [
        {
          title: "انتخاب کنید",
          value: 0,
        },
        {
          title: "مرخصی ساعتی",
          value: 10,
        },
        {
          title: "مرخصی روزانه",
          value: 20,
        },
        {
          title: "ماموریت ساعتی",
          value: 30,
        },
        {
          title: "ماموریت روزانه",
          value: 40,
        },
        {
          title: "مجوز اضافه کاری روزانه",
          value: 50,
        },
        {
          title: "مجوز اضافه کاری ماهانه",
          value: 60,
        },
        {
          title: "درخواست مساعده",
          value: 70,
        },
        {
          title: "درخواست تنخواه",
          value: 80,
        },
        {
          title: "ثبت ورود و خروج فراموش شده",
          value: 90,
        },
        {
          title: "درخواست نیرو",
          value: 100,
        },
        {
          title: "درخواست خدمات",
          value: 110,
        },
        {
          title: "درخواست کالا",
          value: 120,
        },
        {
          title: "تحویل اموال",
          value: 130,
        },
        {
          title: "درخواست کالا از انبار",
          value: 140,
        },
      ],
      additionalChoiceType: [
        {
          title: "استحقاقی",
          value: 10,
        },
        {
          title: "استعلاجی",
          value: 20,
        },
        {
          title: "بدون حقوق",
          value: 30,
        },
      ],
    };
  },
  methods: {
    confirm() {
      this.$emit("onConfirm", this.data);
    },
    prepareData() {
      if (this.item.id > 0) return this.item;
      return {
        id: 0,
        formType: 0,
        requestDatetime: moment().format("YYYY-MM-DDThh:mm:ss"),
        startDatetime: moment().format("YYYY-MM-DDThh:mm:ss"),
        endDatetime: moment().format("YYYY-MM-DDThh:mm:ss"),
        count: 0,
        description: "",
        price: 0,
        additionalData: {},
      };
    },
  },
  computed: {
    showEndDatetime() {
      return (
        this.data.formType == 20 ||
        this.data.formType == 40 ||
        this.data.formType == 60
      );
    },
    needStartTime() {
      if (
        this.data.formType == 10 ||
        this.data.formType == 30 ||
        this.data.formType == 50 ||
        this.data.formType == 90
      )
        return "datetime";
      return "date";
    },
    displayStartTimeFormat() {
      return this.needStartTime == "datetime"
        ? "HH:mm jYYYY/jMM/jDD"
        : "jYYYY/jMM/jDD";
    },
    needEndTime() {
      if (
        this.data.formType == 10 ||
        this.data.formType == 30 ||
        this.data.formType == 50 ||
        this.data.formType == 90
      )
        return "datetime";
      return "date";
    },
    displayEndTimeFormat() {
      return this.needStartTime == "datetime"
        ? "HH:mm jYYYY/jMM/jDD"
        : "jYYYY/jMM/jDD";
    },
    showCount() {
      return (
        this.data.formType == 10 ||
        this.data.formType == 20 ||
        this.data.formType == 30 ||
        this.data.formType == 40 ||
        this.data.formType == 50 ||
        this.data.formType == 60 ||
        this.data.formType == 100
      );
    },
    showPrice() {
      return this.data.formType == 70 || this.data.formType == 80;
    },
    showAdditionalChoiceType() {
      return this.data.formType == 10 || this.data.formType == 20;
    },
    showadditionalDescription() {
      return (
        this.data.formType == 30 ||
        this.data.formType == 40 ||
        this.data.formType == 50 ||
        this.data.formType == 60 ||
        this.data.formType == 70 ||
        this.data.formType == 80 ||
        this.data.formType == 90 ||
        this.data.formType == 100 ||
        this.data.formType == 110
      );
    },
    additionalDescriptionLable() {
      if (this.data.formType == 30 || this.data.formType == 40)
        return "علت ماموریت";
      else if (
        this.data.formType == 50 ||
        this.data.formType == 60 ||
        this.data.formType == 70 ||
        this.data.formType == 80 ||
        this.data.formType == 90
      )
        return "علت";
      else if (this.data.formType == 100) return "شرح وضایف";
      else if (this.data.formType == 110) return "شرح خدمات";
      return "";
    },
  },
};
</script> 
<style >
.vpd-input-group input {
  border: 1px solid #9e9e9e;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  line-height: 39px;
}
.vpd-input-group .vpd-clear-btn {
  line-height: 41px;
}
.vpd-input-group label {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  line-height: 41px;
  background-color: #757575 !important;
  min-width: 130px;
}
</style>
